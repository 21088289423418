import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowCircleLeft,
  faArrowCircleRight,
  faBars,
  faChevronLeft,
  faChevronRight,
  faL,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { useParams, useNavigate, Link } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import { Modal, Button, Spinner, Tooltip, Form } from "react-bootstrap";
import { Editor } from "@tinymce/tinymce-react";
import "../file.css";
import FileChange from "./Modals/FileChange";
import { use } from "react";
import ImageUpload from "./ImageUpload";
// import {processHtml,removeHighlightDivFromString,cleanNestedDivs} from './helpers/editCore'
// Styled components
const LayoutContainer = styled.div`
  display: flex;
  max-width: 100%;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  height: 90vh;
  overflow-y: auto;
`;

const Sidebar = styled.div`
  width: 35%;
  height: 100%;
  overflow: auto;
  padding: 20px;
  background-color: #f7f7f7;
  border-right: 1px solid #ddd;
  transition: transform 0.3s ease;
`;

const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  overflow: hidden;
  width: ${(props) => (props.isSidebarOpen ? "65%" : "100%")};
  margin: auto;
  padding: 20px;
  transition: width 0.3s ease;
`;

const ToggleIcon = styled.div`
  top: 20px;
  left: 20px;
  font-size: 1.5rem;
  color: #2a2a72;
  cursor: pointer;
  z-index: 1000;
  border: 2px solid transparent;
  border-radius: 20px;
  // margin: 10px;
  &:hover {
    // margin: 5px;
    background-color: #e4e7ea;
    // color: white;
  }
`;

const NavigationButton = styled.button`
  background-color: transparent;
  border-radius: 25%;
  border: none;
  font-size: 2rem;
  color: #3a3a8f;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  &:disabled {
    color: #959dad;
    // background-color: #8d8b89;
    cursor: not-allowed;
  }
`;
const CourseTitle = styled.h2`
  font-family: "Roboto";
  background-color: #2a2a72;
  color: white;
  margin: 3% auto;
  font-size: 1.8em;
  font-weight: lighter;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 1px;
  border-bottom: 3px solid #3a3a8f;
  padding-bottom: 10px;
  margin-bottom: 20px;
`;

const ModuleTitle = styled.div`
  font-weight: bold;
  font-size: 1em;
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #3a3a8f;
  background-color: ${({ isActive }) => (isActive ? "#2a2a72" : "#e4e7ea")};
  color: ${({ isActive }) => (isActive ? "#ffffff" : "#3a3a8f")};
  border: ${({ isActive }) => (isActive ? "2px solid #3a3a8f" : "none")};
  transition: background-color 0.3s, color 0.3s;
`;

const LessonTitle = styled.div`
  font-weight: bold;
  padding: 10px;
  // margin-bottom: 5px;
  cursor: pointer;
  color: #3a3a8f;
  background-color: #e4e7ea;
  display: flex;
  justify-content: space-between;
  border-radius: 5px;
`;

const LessonContent = styled.div`
  padding: 10px;
  background-color: #f8f9fa;
  border-radius: 5px;
  margin-top: 10px;
  overflow-y: auto; /* Make lessons scrollable */
  max-height: 100%; /* Limit height to parent container */
  padding-right: 15px; /* Add padding for scrollbar spacing */
`;
const ContentHeader = styled.h5`
  font-size: 1em;
  color: #3a3a8f;
  margin-top: 15px;
  font-weight: bold;
`;

const ContentDescription = styled.p`
  font-size: 0.9em;
  color: #555;
  margin-top: 5px;
`;

const LessonsList = styled.div`
  margin-top: 0.5rem;
  padding-left: 1.5rem;
  //   border-left: 2px solid #3a3a8f;
`;

// Style for each lesson item
const LessonItem = styled.div`
  padding: 10px;
  cursor: pointer;
  font-size: 0.9rem;
  color: #3a3a8f;
  transition: color 0.2s ease-in-out;

  background-color: ${({ isActive }) => (isActive ? "#f0f8ff" : "transparent")};
  font-weight: ${({ isActive }) => (isActive ? "bold" : "normal")};
  border-left: ${({ isActive }) => (isActive ? "2px solid #3a3a8f" : "none")};
  &:hover {
    background-color: #e6f2ff;
  }
  &:hover {
    color: #2a2a6a;
    font-weight: bold;
  }
`;

const NavigationButtonLeft = styled(NavigationButton)`
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  padding: 5px;
  border: "none";
  //   background-color: #2a2a72;
  color: #2a2a72;
`;

const NavigationButtonRight = styled(NavigationButton)`
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  padding: 5px;
  border: "none";
  //   background-color: #2a2a72;
  color: #2a2a72;
`;

const StyledIcon = styled(FontAwesomeIcon)`
  font-size: 1.25rem; /* Adjust icon size */
  transition: transform 0.3s ease-in-out;

  ${NavigationButtonLeft}:hover & {
    transform: scale(1.2); /* Slightly enlarge the icon on hover */
  }
`;
const VideoGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 10px;
`;

const VideoWrapper = styled.div`
  position: relative;
  padding-top: 56.25%;
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

const ImageUploader = styled.div`
  padding: 10px;
  background-color: #f8f9fa;
  border-radius: 5px;
  margin-top: 10px;
  overflow-y: auto;
  max-height: 100%;
  padding-right: 15px;
`;

const CourseData = () => {
  const { id, type } = useParams();
  const [selectedModuleIndex, setSelectedModuleIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [currentLessonIndex, setCurrentLessonIndex] = useState(0);
  const [selectedLessonIndex, setSelectedLessonIndex] = useState(null);
  const [courseOutline, setCourseOutline] = useState({});
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedContent, setSelectedContent] = useState("");
  const [currentContentTitle, setCurrentContentTitle] = useState("");
  const [editType, setEditType] = useState("manual");
  const [generatedContent, setGeneratedContent] = useState(null);
  const [manualStatus, setManualStatus] = useState(true);
  const [userPrompt, setUserPrompt] = useState("");
  const [editorVisible, setEditorVisible] = useState(false);
  const [editorContent, setEditorContent] = useState("");
  const [isHovered, setIsHovered] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [modalShow, setModalShow] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [newImageSrc, setNewImageSrc] = useState("");
  const [selectedTagName, setSelectedTagName] = useState(null);
  const [searchQuery, setSearchQuery] = useState(null);
  const [imageResults, setImageResults] = useState([]);
  const [imageThumbnailModal, setImageThumbnailModal] = useState(false);
  const navigate = useNavigate();
  const divRef = useRef(null);
  const contentRef = useRef(null);
  const detailREf = useRef(null);
  const [showIcons, setShowIcons] = useState(false);
  const [hoveredElement, setHoveredElement] = useState(null);
  const [hoveredBlockElement, setHoveredBlockElement] = useState(null);
  const [bannerPath, setBannerPath] = useState(null);
  const [showThumbnail, setShowThumbnail] = useState(false);
  const [libraryLoading, setLibraryLoading] = useState(false);
  const [blockValue, setBlockValue] = useState(null);
  const [combinedContentValue, setCombinedContentValue] = useState("");
  const BASE_URL = process.env.REACT_APP_API_KEY;
  useEffect(() => {
    if (id) {
      getCourseData(id);
    }

    // Example usage

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [id]);

  // useEffect(() => {
  //   const detailDiv = detailREf.current;

  //   if (detailDiv) {
  //     const handleMouseEnter = (e) => {
  //       const img = e.target;
  //       const separateHandle = ["iframe", "img"];
  //       if (separateHandle.includes(img.tagName.toLowerCase())) {
  //         const container = img.parentElement;

  //         // Avoid duplicating the icon
  //         if (container.querySelector(".hover-icon")) return;

  //         // Create the icon
  //         const icon = document.createElement("span");
  //         icon.textContent = "📌";
  //         icon.className = "hover-icon";
  //         icon.style.position = "absolute";
  //         icon.style.top = `${img.offsetTop + 10}px`;
  //         icon.style.left = `${img.offsetLeft + img.offsetWidth - 30}px`;
  //         icon.style.cursor = "pointer";
  //         icon.style.zIndex = "10";
  //         icon.style.background = "white";
  //         icon.style.border = "1px solid #ccc";
  //         icon.style.borderRadius = "50%";
  //         icon.style.padding = "5px";

  //         // Add click event to the icon
  //         icon.addEventListener("click", () => {
  //           console.log("Icon clicked for image:", img.src);
  //           openModal(img.src);
  //         });

  //         // Ensure the parent has relative positioning
  //         container.style.position = "relative";
  //         container.appendChild(icon);

  //         // Add a listener to remove the icon when the mouse leaves the container
  //         container.addEventListener("mouseleave", () => {
  //           icon.remove();
  //         });
  //       }
  //     };

  //     // Attach event listener to the detailDiv
  //     detailDiv.addEventListener("mouseenter", handleMouseEnter, true);

  //     // Cleanup on component unmount
  //     return () => {
  //       detailDiv.removeEventListener("mouseenter", handleMouseEnter, true);
  //     };
  //   }
  // }, [courseOutline, detailREf]);

  useEffect(() => {
    imgEditRender();
  }, [courseOutline, selectedModuleIndex, currentLessonIndex, editorVisible]);
  const imgEditRender = () => {
    if (contentRef.current) {
      const content = contentRef.current;
      const allElements = content.querySelectorAll("*");
      console.log(allElements);
      const elementsWithListeners = [];
      allElements.forEach((element) => {
        if (
          element.tagName.toLowerCase() == "img" ||
          element.tagName.toLowerCase() == "iframe"
        ) {
          console.log(element.tagName, "--- Adding listener");
          element.addEventListener("mouseover", handleImageMouseHover);
          element.addEventListener("mouseout", handleImageMouseOut);
          elementsWithListeners.push(element);
        }
      });
      // return () => {
      //   elementsWithListeners.forEach((element) => {
      //     console.log(element.tagName, "--- Removing listener");
      //     element.removeEventListener("mouseover", handleImageMouseHover);
      //     element.removeEventListener("mouseout", handleImageMouseOut);
      //   });
      // };
    }
    if (detailREf.current) {
      if (detailREf.current) {
        const content = detailREf.current;

        const allElements = content.querySelectorAll(":scope > div");
        console.log(allElements);
        console.log(allElements);

        const elementsWithListeners = [];
        allElements.forEach((element) => {
          const containsImage = element.querySelector("img");
          const containsIframe = element.querySelector("iframe");
          // console.log(containsImage);
          if (!containsImage && !containsIframe) {
            console.log(element.tagName, "--- Adding listener");
            element.addEventListener("mouseenter", handleBlockMouseHover);
            element.addEventListener("mouseout", handleBlockMouseOut);
            elementsWithListeners.push(element);
          } else {
            console.log("notaddomh");
          }
        });

        return () => {
          elementsWithListeners.forEach((element) => {
            console.log(element.tagName, "--- Removing listener");
            element.removeEventListener("mouseenter", handleBlockMouseHover);
            element.removeEventListener("mouseout", handleBlockMouseOut);
          });
        };
      }
    }
  };
  const getCourseData = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${BASE_URL}/getlist/${id}`, {});
      setLoading(false);

      if (response.data.data.courseOutline) {
        setCourseOutline(response.data.data.courseOutline);
        setSelectedModuleIndex(0);
        setCurrentLessonIndex(0);
        setBannerPath(response.data.data.thumbnailPath);
      } else {
        toast.error(`No course found for the id ${id}`);
        setTimeout(() => {
          navigate("/login-expired");
        }, 2000);
      }
    } catch (error) {
      toast.error(`No course found for the id ${id}`);
      setTimeout(() => {
        navigate("/login-expired");
      }, 2000);
      toast.error("Error fetching course data");
    }
  };

  const handleModuleClick = (index) => {
    setShowThumbnail(false);
    setEditorVisible(false);
    setSelectedModuleIndex(index);
    setCurrentLessonIndex(0);
    scrollToTop();
  };

  const handlePreviousLesson = () => {
    setShowThumbnail(false);

    setEditorVisible(false);
    const currentModuleLessons =
      courseOutline.modules[selectedModuleIndex]?.lessons || [];

    if (currentLessonIndex === 0 && selectedModuleIndex > 0) {
      setSelectedModuleIndex((prevIndex) => prevIndex - 1);
      setCurrentLessonIndex(
        courseOutline.modules[selectedModuleIndex - 1].lessons.length - 1
      );
    } else if (currentLessonIndex > 0) {
      setCurrentLessonIndex((prevIndex) => prevIndex - 1);
    }
    scrollToTop();
  };

  const handleNextLesson = () => {
    setShowThumbnail(false);
    setEditorVisible(false);
    const currentModuleLessons =
      courseOutline.modules[selectedModuleIndex]?.lessons || [];

    if (
      currentLessonIndex === currentModuleLessons.length - 1 &&
      selectedModuleIndex < courseOutline.modules.length - 1
    ) {
      setSelectedModuleIndex((prevIndex) => prevIndex + 1);
      setCurrentLessonIndex(0);
    } else if (currentLessonIndex < currentModuleLessons.length - 1) {
      setCurrentLessonIndex((prevIndex) => prevIndex + 1);
    }
    scrollToTop();
  };
  const handleLessonClick = (moduleIndex, lessonIndex) => {
    console.log(lessonIndex, "---lessonIndedx");
    setShowThumbnail(false);
    setEditorVisible(false);
    setSelectedModuleIndex(moduleIndex);
    setCurrentLessonIndex(lessonIndex);
    scrollToTop();
  };
  const navigateHome = () => {
    navigate("/");
  };
  const scrollToTop = () => {
    if (divRef.current) {
      divRef.current.scrollTop = 0;
    }
  };

  const handleEditClick = () => {
    if (detailREf.current) {
      setCombinedContentValue(detailREf.current.outerHTML);
    }
    console.log(hoveredBlockElement.outerHTML, "---------");
    setBlockValue(hoveredBlockElement.outerHTML);
    // const detailContent =
    //   courseOutline.modules[selectedModuleIndex].lessons[currentLessonIndex]
    //     .combinedContent;
    // console.log(detailContent, "--detailedCOntyent");
    setEditorContent(hoveredBlockElement.outerHTML);
    setEditorVisible(true);
  };

  const handleModalClose = () => {
    setGeneratedContent(null);
    setShowModal(false);
    setSelectedContent("");
    setCurrentContentTitle("");
  };

  const handleMouseOver = () => {
    setIsHovered(true);
  };
  const handleMouseOut = () => {
    setIsHovered(false);
  };

  const handleImageMouseHover = (event) => {
    event.stopPropagation();

    setHoveredElement(event.target);
  };
  const handleImageMouseOut = (event) => {
    if (
      !event.relatedTarget ||
      !contentRef.current.contains(event.relatedTarget)
    ) {
      setShowIcons(false);
      setHoveredElement(null);
    }
  };

  const handleBlockMouseHover = (event) => {
    event.stopPropagation();

    setHoveredBlockElement(event.target);
  };
  const handleBlockMouseOut = (event) => {
    if (
      !event.relatedTarget ||
      !contentRef.current.contains(event.relatedTarget)
    ) {
      setShowIcons(false);
      setHoveredBlockElement(null);
    }
  };

  const handleEditContent = async (type, content) => {
    try {
      setIsLoading(true);
      const response = await axios.post(`${BASE_URL}/edit_course_content`, {
        edit_type: type || editType,
        content: content ? content : selectedContent,
        full_content:
          courseOutline?.modules?.[selectedModuleIndex]?.lessons[
            currentLessonIndex
          ]?.combinedContent || "",
        // full_content: "",
        custom_input: userPrompt,
        title: courseOutline?.modules?.[selectedModuleIndex]?.moduleTitle || "",
      });
      if (response?.data?.success) {
        console.log(response?.data?.data?.content);
        setIsLoading(false);
        if (editType == "custom_input") {
          setEditType("manual");
          setGeneratedContent(response?.data?.data?.content);
        } else {
          setGeneratedContent(response?.data?.data?.content);
        }
      }
    } catch (err) {
      setEditorVisible(false);
      setIsLoading(false);
      // setEditType("manual");
      // setGeneratedContent("");
      console.log(err);

      if (err?.response?.data?.error) {
        toast.error(err?.response?.data?.error);
      }
    }
  };

  const handleSaveChange = () => {
    const updatedCourseOutline = { ...courseOutline };
    console.log(generatedContent);
    if (generatedContent == "" || generatedContent == null) {
      toast.warn("Content can't be empty to update");
    } else {
      console.log(currentLessonIndex, "=----------------------");

      const rep = combinedContentValue.replace(
        selectedContent,
        generatedContent
      );
      const cleanedContent = cleanNestedDivs(rep);

      // const replace_content = updatedCourseOutline.modules[
      //   selectedModuleIndex
      // ].lessons[currentLessonIndex].combinedContent.replace(
      //   selectedContent,
      //   generatedContent
      // );

      const replace_content = cleanedContent.replace(/^<div>|<\/div>$/g, "");
      const replace_combined_content = combinedContentValue.replace(
        selectedContent,
        `<div class="highlight">${generatedContent}</div>`
      );
      const highlight_content = cleanNestedDivs(
        replace_combined_content
      ).replace(/^<div>|<\/div>$/g, "");

      console.log(replace_content);

      console.log(selectedContent, "henettedg", generatedContent);
      // return;
      updatedCourseOutline.modules[selectedModuleIndex].lessons[
        currentLessonIndex
      ].combinedContent = highlight_content;
      handleEditSaveClick(replace_content);
      console.log("udpatedCourse0dofdeofWEGIJ", updatedCourseOutline);
      setCourseOutline(updatedCourseOutline);
      setEditorVisible(false);
      setShowModal(false);
    }
  };
  const handleEditSaveClick = async (updatedContent) => {
    // const replace_content = updatedContent.replace(regex, "");
    const replace_content = removeHighlightDivFromString(updatedContent);

    console.log(replace_content, "----");
    try {
      const response = await axios.post(`${BASE_URL}/update_content`, {
        uuid: id,
        content: replace_content,
        module: selectedModuleIndex + 1,
        lesson:
          `${
            selectedModuleIndex
              ? selectedModuleIndex + 1
              : selectedModuleIndex + 1
          }.${currentLessonIndex + 1}` || "1.1",
      });
      if (response.status) {
        setGeneratedContent("");
        toast.success(response?.data?.message || "updated succesfully");
      }
    } catch (error) {
      setGeneratedContent("");
      console.log(error);
    }
  };

  const handleEditorSave = () => {
    // Save the updated content
    const updatedCourseOutline = { ...courseOutline };
    console.log(editorContent, "---");
    const rep = combinedContentValue.replace(blockValue, editorContent);
    const cleanedContent = cleanNestedDivs(rep);
    // const cleanedContent = rep;
    const replace_content = removeHighlightDivFromString(
      cleanedContent
    ).replace(/^<div>|<\/div>$/g, "");
    console.log(hoveredBlockElement);
    console.log(replace_content, "------");

    updatedCourseOutline.modules[selectedModuleIndex].lessons[
      currentLessonIndex
    ].combinedContent = replace_content;
    if (replace_content == "" || replace_content == null) {
      toast.warn("Content can't be empty to update");
    } else {
      handleEditSaveClick(replace_content);
      console.log(updatedCourseOutline);
      setCourseOutline(updatedCourseOutline);
      setEditorVisible(false);
    }
  };

  const handleEditorSetup = (editor) => {
    // Custom Plugin 1: Insert Timestamp
    editor.ui.registry.addButton("rephrase", {
      tooltip: "Rephrase",
      icon: "repharse",
      onAction: () => {
        const selectedText = editor.selection.getContent({ format: "html" });
        setEditType("rephrase");
        if (selectedText) {
          setSelectedContent(selectedText);
          handleEditContent("rephrase", selectedText);
        } else {
          // const currentContent =
          //   courseOutline.modules[selectedModuleIndex].lessons[
          //     currentLessonIndex
          //   ].combinedContent;
          console.log(hoveredBlockElement.outerHTML, "----");
          handleEditContent("rephrase", hoveredBlockElement.outerHTML);

          setSelectedContent(hoveredBlockElement.outerHTML);
        }
        setShowModal(true);
      },
    });

    editor.ui.registry.addButton("summarize", {
      tooltip: "Summarize",
      icon: "summarize",
      onAction: () => {
        const selectedText = editor.selection.getContent({ format: "html" });
        setEditType("summarize");
        if (selectedText) {
          setSelectedContent(selectedText);
          handleEditContent("summarize", selectedText);
        } else {
          // const currentContent =
          //   courseOutline.modules[selectedModuleIndex].lessons[
          //     currentLessonIndex
          //   ].combinedContent;
          setSelectedContent(hoveredBlockElement.outerHTML);
          handleEditContent("summarize", hoveredBlockElement.outerHTML);
        }
        setShowModal(true);
      },
    });

    editor.ui.registry.addButton("customprompt", {
      tooltip: "Custom Prompt",
      icon: "custom-timestamp",
      onAction: () => {
        const selectedText = editor.selection.getContent({ format: "html" });
        setEditType("custom_input");
        if (selectedText) {
          setSelectedContent(selectedText);
        } else {
          // const currentContent =
          //   courseOutline.modules[selectedModuleIndex].lessons[
          //     currentLessonIndex
          //   ].combinedContent;
          setSelectedContent(hoveredBlockElement.outerHTML);
        }
        setShowModal(true);
      },
    });

    editor.ui.registry.addIcon(
      "repharse",
      `<svg  width="24" height="24 xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="green" class="custom_icons">
  <path d="M17.65,6.35A8,8,0,1,0,19,12h-2a6,6,0,1,1-1.76-4.24L12,10h8V2l-2.35,2.35ZM13,12v4h4v2H13a2,2,0,0,1-2-2V12Z"/>
</svg>`
    );
    editor.ui.registry.addIcon(
      "summarize",
      `<svg  width="24" height="24 xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"  class="custom_icons">
  <path d="M3 2h18a1 1 0 0 1 1 1v18a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3a1 1 0 0 1 1-1zm3 4v2h12V6H6zm0 4v2h8v-2H6zm0 4v2h10v-2H6z"/>
</svg>`
    );

    editor.ui.registry.addIcon(
      "custom-timestamp",
      `<svg
  xmlns="http://www.w3.org/2000/svg"
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"

 
   class="custom_icons"
>
  <path d="M12 20c4.418 0 8-3.582 8-8s-3.582-8-8-8-8 3.582-8 8c0 1.826.61 3.507 1.635 4.847l-.96 3.535a1 1 0 0 0 1.261 1.217l3.73-1.21A7.957 7.957 0 0 0 12 20z" />
  <circle cx="12" cy="12" r="2" />
  <path d="M9 10h6" />
</svg>`
    );
  };
  const handleClickOutside = (event) => {
    console.log(event, "----", courseOutline);
    if (contentRef.current && !contentRef.current.contains(event.target)) {
      const updatedCourseOutline = { ...courseOutline };

      if (contentRef.current && !contentRef.current.contains(event.target)) {
        // const highlightedElements =
        //   contentRef.current.querySelectorAll(".highlight");
        // highlightedElements.forEach((el) => {
        //   el.classList.remove("highlight");
        // });
      }
      if (updatedCourseOutline?.modules?.length) {
        const module = updatedCourseOutline.modules[selectedModuleIndex];
        const lesson = module.lessons[currentLessonIndex];
        lesson.combinedContent = removeHighlightDivFromString(
          lesson.combinedContent
        );

        console.log(updatedCourseOutline, "---");

        setCourseOutline(updatedCourseOutline);
      }
    }
  };

  function removeHighlightDivFromString(htmlString) {
    // Parse the HTML string into a DOM structure
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, "text/html");

    // Find the highlight div
    const highlightDiv = doc.querySelector(".highlight");

    if (highlightDiv) {
      // Extract the inner content
      const innerContent = highlightDiv.innerHTML;

      // Replace the highlight div with its inner content
      const parent = highlightDiv.parentNode;
      parent.replaceChild(
        document.createRange().createContextualFragment(innerContent),
        highlightDiv
      );
    }
    console.log(doc.body);
    return doc.body.innerHTML;
  }

  const toggleSidebar = () => {
    setIsSidebarOpen((prev) => !prev);
  };

  const openModal = (imageSrc, tagName) => {
    console.log(tagName);
    setSelectedTagName(tagName.toLowerCase());
    setSelectedImage(imageSrc);
    setModalShow(true);
  };

  const closeModal = () => {
    setModalShow(false);
    setSelectedImage("");
    setNewImageSrc(null);
    setImageResults([]);
    setUploadedFile(null);
    setNewImageSrc("");
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setUploadedFile(file);
      console.log("Selected file:", file.name);
    }
  };

  const handleDragFileChange = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    if (file) {
      setUploadedFile(file);
      console.log("Selected file:", file.name);
    }
  };

  const handleUpload = async () => {
    try {
      if (!uploadedFile || !selectedImage) {
        alert("Please select a file to upload!");
        return;
      }
      const formData = new FormData();
      formData.append("file", uploadedFile);
      const response = await axios.post(`${BASE_URL}/upload`, formData);
      console.log(response);
      if (response.data.data.filename) {
        setNewImageSrc(response.data.data.filename);
      }
    } catch (error) {
      console.log(error);
      if (error?.response?.data?.error) {
        toast.error(error?.response?.data.data.error);
      }
    }
  };

  const handleHoveredElementClick = () => {
    console.log(hoveredElement, "---------");
    const imgsrc = hoveredElement.src;
    openModal(imgsrc, hoveredElement.tagName);
  };

  const handleReplaceImage = async () => {
    if (selectedTagName == "iframe" && !validateYouTubeUrl(newImageSrc)) {
      alert("Enter valid Youtube URL");
      return;
    }
    const updatedCourseOutline = { ...courseOutline };
    console.log(selectedImage, newImageSrc);
    const replace_content = updatedCourseOutline.modules[
      selectedModuleIndex
    ].lessons[currentLessonIndex].combinedContent.replace(/&amp;/g, "&");
    const updatedContent = replace_content.replace(selectedImage, newImageSrc);
    console.log(updatedContent);
    handleEditSaveClick(updatedContent);
    updatedCourseOutline.modules[selectedModuleIndex].lessons[
      currentLessonIndex
    ].combinedContent = updatedContent;
    setCourseOutline(updatedCourseOutline);
    setShowModal(false);
    setModalShow(false);
    setSelectedImage("");
    setNewImageSrc(null);
    setImageResults([]);
    setUploadedFile(null);
    setNewImageSrc("");
  };

  const newImageUrlChange = (url) => {
    setNewImageSrc(url);
  };
  const handlesearchInput = async (e) => {
    try {
      setSearchQuery(e.target.value);
      // if (e.target.value.length > 3) {
      //   const response = await axios.get(`${BASE_URL}/search-images`, {
      //     params: {
      //       query: e.target.value,
      //     },
      //   });
      //   if (response.data.data.images) {
      //     setImageResults(response.data.data.images);
      //   }
      // }
    } catch (error) {
      console.error(error);
    }
  };
  const handleSearchClick = async () => {
    try {
      setLibraryLoading(true);
      const response = await axios.get(`${BASE_URL}/search-images`, {
        params: {
          query: searchQuery,
        },
      });
      if (response.data.data.images) {
        setImageResults(response.data.data.images);
      }
      setLibraryLoading(false);
    } catch (error) {
      setLibraryLoading(false);
      console.error(error);
      toast.error("Error Fetching Images");
    }
  };
  const handleCourseThumbnail = async () => {
    setImageThumbnailModal(true);
  };
  const handleThumbnailModalClose = async () => {
    setImageThumbnailModal(false);
  };
  const handleBannerImageChange = (path) => {
    setBannerPath(path);
  };

  const processHtml = (html) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, "text/html");
    const containImg = doc.body.querySelectorAll("img");
    const groupedDivs = [];

    if (containImg.length) {
      const divs = [...doc.body.querySelectorAll(":scope > *")];
      let currentGroup = "";
      let indexCounter = 0;

      divs.forEach((div, index) => {
        const containsImage = div.querySelector("img");
        const containsIframe = div.querySelector("iframe");
        // console.log(containsImage, index);
        if (containsImage || containsIframe) {
          if (currentGroup) {
            groupedDivs.push({
              content: currentGroup,
              index: indexCounter++,
              containsImage: false,
            });
            currentGroup = "";
          }
          groupedDivs.push({
            content: div.outerHTML,
            index: indexCounter++,
            containsImage: true,
          });
        } else {
          currentGroup += div.outerHTML;
          // console.log(currentGroup);
        }
      });

      if (currentGroup) {
        groupedDivs.push({
          content: currentGroup,
          index: indexCounter,
          containsImage: false,
        });
      }
      console.log(groupedDivs);
      return groupedDivs;
    } else {
      console.log(html, doc.body);
      groupedDivs.push({
        content: doc.body.outerHTML,
        index: 0,
        containsImage: false,
      });
      return groupedDivs;
    }
  };

  function cleanNestedDivs(html) {
    // Parse the HTML string into a DOM structure
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, "text/html");

    // Recursive function to clean unnecessary divs
    function cleanDivs(element) {
      if (!element || element.nodeName !== "DIV") return;

      if (element.children.length === 1) {
        const child = element.firstElementChild;

        // If the only child is an <img>, keep the <div>
        if (child.nodeName === "IMG") {
          return;
        }

        // If the <div> has no attributes and no <img>, replace it with its child
        if (element.attributes.length === 0) {
          element.replaceWith(child);
          cleanDivs(child); // Recurse into the child
        }
      } else {
        // Recursively clean child elements
        Array.from(element.children).forEach(cleanDivs);
      }
    }

    // Clean all <div> elements inside the body
    Array.from(doc.body.querySelectorAll("div")).forEach(cleanDivs);

    // Return the cleaned HTML as a string
    return doc.body.innerHTML.trim();
  }

  const validateYouTubeUrl = (url) => {
    const youtubeRegex =
      /^(https?:\/\/)?(www\.)?(youtube\.com\/(watch\?v=|embed\/)|youtu\.be\/)[a-zA-Z0-9_-]{11}$/;
    return youtubeRegex.test(url);
  };

  return (
    <>
      <CourseTitle className="m-0 shadow-lg p-3">
        {courseOutline?.title || ""}
      </CourseTitle>
      {courseOutline?.modules && (
        <LayoutContainer className="pr-2" style={{ scrollbarWidth: "none" }}>
          {isSidebarOpen && (
            <Sidebar className="rounded shadow-sm mt-3 z-3">
              <div className="d-flex mb-2">
                {type == "internal" && (
                  <button
                    className="m-0 mr-3 rounded-circle"
                    onClick={navigateHome}
                    title="Back home"
                    style={{
                      marginLeft: "4%",
                      border: "none",
                      backgroundColor: "inherit",
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faArrowCircleLeft}
                      style={{
                        color: "#3a3a8f",
                        fontSize: "2rem",
                        cursor: "pointer",
                      }}
                    />
                  </button>
                )}
                <h2>Chapters</h2>
              </div>

              {courseOutline?.modules?.map((module, index) => (
                <div key={index}>
                  <ModuleTitle
                    isActive={selectedModuleIndex === index}
                    onClick={() => handleModuleClick(index)}
                    className="mb-3"
                  >
                    <div>{`Chapter ${module.moduleNumber}: ${module.moduleTitle}`}</div>
                  </ModuleTitle>

                  {selectedModuleIndex === index && (
                    <LessonsList>
                      {courseOutline.modules[selectedModuleIndex].lessons.map(
                        (lesson, lessonIndex) => (
                          <LessonItem
                            className="mb-1"
                            key={lessonIndex}
                            onClick={() =>
                              handleLessonClick(
                                selectedModuleIndex,
                                lessonIndex
                              )
                            }
                            isActive={currentLessonIndex === lessonIndex}
                          >
                            {`Lesson ${lesson.lessonNumber}: ${lesson.lessonTitle}`}
                          </LessonItem>
                        )
                      )}
                    </LessonsList>
                  )}
                </div>
              ))}
              <ModuleTitle
                isActive={showThumbnail ? true : false}
                onClick={() => {
                  handleCourseThumbnail();
                  setShowThumbnail(true);
                  setSelectedModuleIndex(-1);
                  setHoveredElement(null);
                }}
              >
                Upload Course Banner
              </ModuleTitle>
            </Sidebar>
          )}

          <MainContent className="p-2">
            {showThumbnail ? (
              <ImageUploader>
                <ImageUpload
                  showModal={imageThumbnailModal}
                  closeModal={handleThumbnailModalClose}
                  id={id}
                  bannerImage={bannerPath}
                  handleBannerImageChange={handleBannerImageChange}
                  newImageUrlChange={newImageUrlChange}
                  searchQuery={searchQuery}
                  handleSearchClick={handleSearchClick}
                  handleSearchChange={handlesearchInput}
                  imageResults={imageResults}
                  libraryLoading={libraryLoading}
                  selectedImage={selectedImage}
                />
              </ImageUploader>
            ) : (
              selectedModuleIndex !== null && (
                <>
                  <NavigationButtonLeft
                    title="Previous"
                    className="m-0 mr-3 ml-2"
                    onClick={handlePreviousLesson}
                    disabled={
                      selectedModuleIndex === 0 && currentLessonIndex === 0
                    }
                  >
                    <StyledIcon icon={faChevronLeft} />
                  </NavigationButtonLeft>

                  <div
                    style={{ overflow: "auto", scrollbarWidth: "none" }}
                    className="px-2 m-3"
                    ref={divRef}
                  >
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <ToggleIcon
                        onClick={toggleSidebar}
                        title={
                          isSidebarOpen ? "Collapse Sidebar" : "Expand Sidebar"
                        }
                        className="mr-3"
                      >
                        <FontAwesomeIcon icon={faBars} />
                      </ToggleIcon>
                      <h3>
                        {courseOutline.modules[selectedModuleIndex].moduleTitle}
                      </h3>
                    </div>
                    {courseOutline.modules[selectedModuleIndex].lessons.length >
                      0 && (
                      <div className="lesson-carousel">
                        <div className="lesson-content">
                          <LessonTitle>
                            {`Lesson ${courseOutline.modules[selectedModuleIndex].lessons[currentLessonIndex].lessonNumber}: ${courseOutline.modules[selectedModuleIndex].lessons[currentLessonIndex].lessonTitle}`}
                          </LessonTitle>
                          <LessonContent>
                            <p
                              className="p-2 px-3 bg-light shadow-sm rounded-sm"
                              style={{
                                fontWeight: "bold",
                                border: "1px solid #ddd",
                              }}
                            >
                              {
                                courseOutline.modules[selectedModuleIndex]
                                  .lessons[currentLessonIndex].description
                              }
                            </p>

                            {courseOutline.modules[selectedModuleIndex].lessons[
                              currentLessonIndex
                            ].combinedContent && (
                              <div
                                className="mt-2 px-2"
                                style={{ position: "relative" }}
                                ref={contentRef}
                              >
                                {!editorVisible && (
                                  <>
                                    <div ref={detailREf}>
                                      {processHtml(
                                        courseOutline.modules[
                                          selectedModuleIndex
                                        ].lessons[currentLessonIndex]
                                          .combinedContent
                                      ).map(({ content }) => (
                                        <div
                                          dangerouslySetInnerHTML={{
                                            __html: content,
                                          }}
                                        />
                                      ))}
                                    </div>
                                    {isHovered && (
                                      <div
                                        className="edit-icon"
                                        title="Edit"
                                        style={{
                                          position: "absolute",
                                          top: "-15px",
                                          right: "0px",
                                          cursor: "pointer",
                                          color: "blue",
                                          fontSize: "18px",
                                          backgroundColor: "#e4e7ea",
                                          padding: "5px",
                                          borderRadius: "50%",
                                        }}
                                        onClick={() => {
                                          console.log(
                                            hoveredBlockElement.outerHTML
                                          );

                                          handleEditClick();
                                        }}
                                      >
                                        ✏️
                                      </div>
                                    )}
                                  </>
                                )}
                                {editorVisible && (
                                  <div
                                    style={{
                                      position: "relative",
                                      backgroundColor: "#fff",
                                      zIndex: 10,
                                      // border: "1px solid #ccc",
                                      padding: "10px",
                                    }}
                                  >
                                    <Editor
                                      tinymceScriptSrc="/tinymce/tinymce.min.js"
                                      apiKey="v42s7rzldxrmpx1tk20yvkyp8rd28dy3o56wg7qao79e42vf"
                                      value={editorContent}
                                      init={{
                                        height: 400,
                                        menubar: false,
                                        plugins:
                                          "lists link image preview contextmenu",
                                        toolbar:
                                          "undo redo | formatselect | bold italic alignleft aligncenter alignright | bullist numlist outdent indent | link rephrase summarize customprompt",
                                        setup: handleEditorSetup,
                                      }}
                                      onEditorChange={(newContent) => {
                                        console.log(newContent);
                                        setEditorContent(newContent);
                                      }}
                                    />

                                    <div className="d-flex justify-content-end">
                                      <div className="alert alert-warning col-md-6 mt-2 d-flex align-items-center">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="16"
                                          height="16"
                                          fill="currentColor"
                                          class="bi bi-exclamation-triangle"
                                          viewBox="0 0 16 16"
                                        >
                                          <path d="M7.938 2.016A.13.13 0 0 1 8.002 2a.13.13 0 0 1 .063.016.15.15 0 0 1 .054.057l6.857 11.667c.036.06.035.124.002.183a.2.2 0 0 1-.054.06.1.1 0 0 1-.066.017H1.146a.1.1 0 0 1-.066-.017.2.2 0 0 1-.054-.06.18.18 0 0 1 .002-.183L7.884 2.073a.15.15 0 0 1 .054-.057m1.044-.45a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767z" />
                                          <path d="M7.002 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0M7.1 5.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0z" />
                                        </svg>
                                        <span className="me-2 ml-2">
                                          Clicking 'Save' will update the
                                          database with the changes.
                                        </span>
                                      </div>

                                      <div
                                        style={{ marginTop: "10px" }}
                                        className=""
                                      >
                                        <button
                                          onClick={() => {
                                            handleEditorSave();
                                            imgEditRender();
                                          }}
                                          style={{
                                            backgroundColor: "#3a3a8f",
                                            color: "white",
                                            marginRight: "10px",
                                          }}
                                          className="btn"
                                        >
                                          Save
                                        </button>
                                        <button
                                          className="btn btn-secondary"
                                          onClick={() => {
                                            setEditorVisible(false);
                                            imgEditRender();
                                          }}
                                        >
                                          Cancel
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                )}
                                {hoveredElement && (
                                  <>
                                    <div
                                      className="edit-icon"
                                      title="edit"
                                      style={{
                                        position: "absolute",
                                        top:
                                          hoveredElement?.getBoundingClientRect()
                                            .top -
                                          contentRef?.current?.getBoundingClientRect()
                                            .top +
                                          "px",
                                        left:
                                          hoveredElement?.getBoundingClientRect()
                                            .right -
                                          contentRef?.current?.getBoundingClientRect()
                                            .left -
                                          20 +
                                          "px",
                                        cursor: "pointer",
                                        color: "blue",
                                        fontSize: "18px",
                                        transform: "translateY(-50%)",
                                        backgroundColor: "#e4e7ea",
                                      }}
                                      onMouseOut={handleMouseOut}
                                      onMouseOver={() =>
                                        setHoveredElement(hoveredElement)
                                      }
                                      onClick={() =>
                                        handleHoveredElementClick()
                                      }
                                    >
                                      ✏️
                                    </div>
                                  </>
                                )}

                                {hoveredBlockElement && (
                                  <>
                                    <div
                                      className="edit-icon"
                                      title="edit"
                                      style={{
                                        position: "absolute",
                                        top:
                                          hoveredBlockElement?.getBoundingClientRect()
                                            .top -
                                          detailREf?.current?.getBoundingClientRect()
                                            .top +
                                          "px",
                                        left:
                                          hoveredBlockElement?.getBoundingClientRect()
                                            .right -
                                          detailREf?.current?.getBoundingClientRect()
                                            .left -
                                          20 +
                                          "px",
                                        cursor: "pointer",
                                        color: "blue",
                                        fontSize: "18px",
                                        transform: "translateY(-50%)",
                                        backgroundColor: "#e4e7ea",
                                      }}
                                      // onMouseLeave={handleMouseOut}
                                      // onMouseEnter={() =>
                                      //   setHoveredBlockElement(hoveredElement)
                                      // }
                                      // onClick={() =>
                                      //   handleHoveredElementClick()
                                      // }
                                      onClick={() => {
                                        handleEditClick();
                                      }}
                                    >
                                      ✏️
                                    </div>
                                  </>
                                )}
                              </div>
                            )}

                            {/* {courseOutline.modules[selectedModuleIndex].lessons[
                            currentLessonIndex
                          ].content &&
                            courseOutline.modules[selectedModuleIndex].lessons[
                              currentLessonIndex
                            ].content.map((contentItem, contentIndex) => (
                              <div
                                key={contentIndex}
                                style={{ position: "relative" }}
                                // onMouseEnter={(e) =>
                                //   (e.currentTarget.querySelector(
                                //     ".edit-icon"
                                //   ).style.display = "block")
                                // }
                                // onMouseLeave={(e) =>
                                //   (e.currentTarget.querySelector(
                                //     ".edit-icon"
                                //   ).style.display = "none")
                                // }
                              >
                                <ContentHeader>
                                  {`${selectedModuleIndex + 1}.${
                                    currentLessonIndex + 1
                                  }.${contentIndex + 1} ${
                                    contentItem.titleName
                                  }`}
                                </ContentHeader>
                                <ContentDescription>
                                  {contentItem.titleDescription}
                                </ContentDescription>
                                {contentItem?.youtubeSearchTerms && (
                                  <p>
                                    <strong>YouTube Search Terms:</strong>
                                  </p>
                                )}
                                <ul>
                                  {contentItem?.youtubeSearchTerms?.map(
                                    (term, termIndex) => (
                                      <li key={termIndex}>{term}</li>
                                    )
                                  )}
                                </ul>
                                {contentItem?.YoutubeLinks && (
                                  <VideoGrid>
                                    {contentItem.YoutubeLinks.map(
                                      (linkGroup, i) => (
                                        <VideoWrapper key={i}>
                                          <iframe
                                            src={
                                              linkGroup && linkGroup[0]?.URL
                                                ? linkGroup[0].URL
                                                : linkGroup?.URL
                                            }
                                            title={
                                              linkGroup && linkGroup[0]?.title
                                                ? linkGroup[0].title
                                                : linkGroup?.title
                                            }
                                            className="rounded shadow-sm"
                                            style={{ border: "none" }}
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                          ></iframe>
                                        </VideoWrapper>
                                      )
                                    )}
                                  </VideoGrid>
                                )}
                              </div>
                            ))} */}
                            <p
                              className="my-2 px-2"
                              style={{
                                fontFamily: "sans-serif",
                                fontSize: "18px",
                                fontWeight: "bold",
                                color: "#212529",
                              }}
                            >
                              YouTube Job Aids
                            </p>
                            <div
                              className=""
                              style={{
                                display: "flex",
                                flexWrap: "wrap",
                                justifyContent: "flex-start",
                              }}
                            >
                              {courseOutline?.modules?.[
                                selectedModuleIndex
                              ]?.lessons?.[currentLessonIndex]?.content?.map(
                                (youtubeContent) =>
                                  youtubeContent?.YoutubeJobAids?.map(
                                    (jobAids) => (
                                      <div className="col-4 mb-4">
                                        <div className="embed-responsive embed-responsive-16by9 rounded shadow-sm">
                                          <iframe
                                            className="embed-responsive-item"
                                            src={jobAids.URL}
                                          ></iframe>
                                        </div>
                                      </div>
                                    )
                                  )
                              )}
                            </div>
                          </LessonContent>
                        </div>
                      </div>
                    )}
                  </div>
                  <NavigationButtonRight
                    title="next"
                    className="m-0 mr-2"
                    onClick={handleNextLesson}
                    disabled={
                      selectedModuleIndex ===
                        courseOutline.modules.length - 1 &&
                      currentLessonIndex ===
                        courseOutline.modules[selectedModuleIndex].lessons
                          .length -
                          1
                    }
                  >
                    <StyledIcon icon={faChevronRight} />
                  </NavigationButtonRight>
                </>
              )
            )}
          </MainContent>

          <Modal
            show={showModal}
            onHide={handleModalClose}
            fullscreen
            dialogClassName="custom-modal"
            bsClass="my-modal"
          >
            <Modal.Header>
              <Modal.Title>Update Content</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div
                style={{
                  display: "flex",
                  gap: "20px",
                  alignItems: "flex-start",
                }}
              >
                {/* Left Pane: Existing Content */}
                <div
                  style={{
                    width: "100%",
                    flex: 1,
                    padding: "15px",
                    border: "1px solid #ddd",
                    borderRadius: "8px",
                    backgroundColor: "#f5f5f5",
                  }}
                >
                  <h5 style={{ marginBottom: "10px", fontWeight: "bold" }}>
                    Existing Content
                  </h5>

                  <Editor
                    tinymceScriptSrc="/tinymce/tinymce.min.js"
                    apiKey="v42s7rzldxrmpx1tk20yvkyp8rd28dy3o56wg7qao79e42vf"
                    value={selectedContent}
                    disabled={true}
                    init={{
                      height: 500,
                      menubar: false,
                      toolbar: false,
                    }}
                  />
                </div>

                <div
                  style={{
                    flex: 1,
                    padding: "15px",
                    border: "1px solid #ddd",
                    borderRadius: "8px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {isLoading ? (
                    <Spinner animation="border" role="status" variant="primary">
                      {/* <span className="visually-hidden">Loading...</span> */}
                    </Spinner>
                  ) : (
                    <>
                      {(generatedContent ||
                        editType == "custom_input" ||
                        manualStatus) && (
                        <div style={{ width: "100%" }}>
                          <h5
                            style={{
                              marginBottom: "10px",
                              fontWeight: "bold",
                            }}
                          >
                            {editType == "manual" && <>custom_input Content</>}
                            {editType == "rephrase" && <>Rephrased Content</>}
                            {editType == "summarize" && <>Summarized Content</>}
                            {editType == "custom_input" && <>Custom Prompt</>}
                          </h5>

                          <Editor
                            tinymceScriptSrc="/tinymce/tinymce.min.js"
                            apiKey="v42s7rzldxrmpx1tk20yvkyp8rd28dy3o56wg7qao79e42vf"
                            value={
                              editType == "custom_input"
                                ? userPrompt
                                : generatedContent
                            }
                            init={{
                              height: 500,
                              menubar: false,
                              forced_root_block: "div",
                              toolbar:
                                "undo redo | formatselect | bold italic alignleft aligncenter alignright | bullist numlist outdent indent | link image rephrase summarize customprompt",
                              image_caption: true,
                              image_title: true,
                              automatic_uploads: true,
                              file_picker_types: "image",
                              file_picker_callback: (callback, value, meta) => {
                                if (meta.filetype === "image") {
                                  const input = document.createElement("input");
                                  input.setAttribute("type", "file");
                                  input.setAttribute("accept", "image/*");

                                  input.onchange = function () {
                                    const file = this.files[0];
                                    const reader = new FileReader();
                                    reader.onload = function () {
                                      callback(reader.result, {
                                        alt: file.name,
                                      });
                                    };
                                    reader.readAsDataURL(file);
                                  };

                                  input.click();
                                }
                              },
                            }}
                            onEditorChange={(e) => {
                              // console.log(e);
                              editType == "custom_input"
                                ? setUserPrompt(e)
                                : setGeneratedContent(e);
                            }}
                          />
                          {editType == "custom_input" && (
                            <div className="d-flex justify-content-end">
                              <button
                                type="button"
                                onClick={() => {
                                  handleEditContent(editType);
                                }}
                                className="btn btn-primary "
                                style={{
                                  backgroundColor: "#3a3a8f",
                                  color: "white",
                                }}
                              >
                                Generate with Prompt
                              </button>
                            </div>
                          )}
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <div className="alert alert-warning col-md-6  d-flex align-items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-exclamation-triangle"
                  viewBox="0 0 16 16"
                >
                  <path d="M7.938 2.016A.13.13 0 0 1 8.002 2a.13.13 0 0 1 .063.016.15.15 0 0 1 .054.057l6.857 11.667c.036.06.035.124.002.183a.2.2 0 0 1-.054.06.1.1 0 0 1-.066.017H1.146a.1.1 0 0 1-.066-.017.2.2 0 0 1-.054-.06.18.18 0 0 1 .002-.183L7.884 2.073a.15.15 0 0 1 .054-.057m1.044-.45a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767z" />
                  <path d="M7.002 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0M7.1 5.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0z" />
                </svg>
                <span className="me-2 ml-2">
                  Clicking 'Save' will update the database with the changes.
                </span>
              </div>
              <Button
                variant="primary"
                onClick={() => handleSaveChange()}
                style={{
                  backgroundColor: "#3a3a8f",
                  color: "white",
                }}
              >
                Save Changes
              </Button>
              <Button variant="secondary" onClick={handleModalClose}>
                Cancel
              </Button>
            </Modal.Footer>
          </Modal>

          <FileChange
            modalShow={modalShow}
            selectedImage={selectedImage}
            handleFileChange={handleFileChange}
            handleDragFileChange={handleDragFileChange}
            uploadedFile={uploadedFile}
            closeModal={closeModal}
            handleUpload={handleUpload}
            newImageSrc={newImageSrc}
            handleReplaceImage={handleReplaceImage}
            selectedTagName={selectedTagName}
            newImageUrlChange={newImageUrlChange}
            searchQuery={searchQuery}
            handleSearchChange={handlesearchInput}
            imageResults={imageResults}
            handleSearchClick={handleSearchClick}
            libraryLoading={libraryLoading}
            contents={
              courseOutline?.modules?.[selectedModuleIndex]?.lessons?.[
                currentLessonIndex
              ]?.content
            }
          />

          {/* <ImageUpload
            showModal={imageThumbnailModal}
            closeModal={handleThumbnailModalClose}
            id={id}
            bannerImage={bannerPath}
            handleBannerImageChange={handleBannerImageChange}
          /> */}
        </LayoutContainer>
      )}
      {loading && (
        <div className="container-loader">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      )}
    </>
  );
};

export default CourseData;
