import { useState } from "react";
import MultiRangeSlider from "./MultiRangeSlider";
import { toast } from "react-toastify";

const FilterPopUp = ({ handleOverlayClick, handleGenerateParameter, filterValues, handleFilterApplied }) => {
    const [minYear, setMinYear] = useState(filterValues[0]);
    const [maxYear, setMaxYear] = useState(filterValues[1]);
    const [videoDuration, setVideoDuration] = useState(filterValues[2]);

    const radioOptions = [
        { value: "less4_relevance", label: "Under 4 minutes" },
        { value: "4to20_relevance", label: "4 to 20 minutes" },
        { value: "greater20_relevance", label: "Over 20 minutes" },
    ];

    const handlereset = () => {
        // setMaxYear(10);
        // setMinYear(0);
        // setVideoDuration(null);
        // console.log(minYear,maxYear,videoDuration)
        handleGenerateParameter(0, 10, null);
        handleFilterApplied(false);
        handleOverlayClick();
        toast.success("Filter Resetted");
    };

    const handleFilterApply = () => {
        handleGenerateParameter(minYear, maxYear, videoDuration);
        handleFilterApplied(true);
        // console.log(minYear, maxYear, videoDuration);
        handleOverlayClick();        
    };
    return (
        <>
            {/* Modal Overlay */}
            <div
                className='modal fade show'
                style={{ position: "fixed", justifyContent: "center", display: "flex", alignItems: "center" }}
                tabIndex='-1'
                role='dialog'
                onClick={() => handleOverlayClick()}
            >
                <div className='modal-dialog modal-dialog-centered' role='document' onClick={(e) => e.stopPropagation()}>
                    <div className='modal-content' style={{ height: "auto", borderRadius: "10px" }}>
                        <div className='modal-header px-0 mx-3' style={{ borderBottom: "2px solid #3a3a8f" }}>
                            <h4 className='modal-title'>Filter</h4>
                            <button type='button' className='close' aria-label='Close' onClick={() => handleOverlayClick()}>
                                <span aria-hidden='true'>&times;</span>
                            </button>
                        </div>
                        <div className='modal-body mt-10' style={{ flex: "0 0 auto" }}>
                            <h5 className='modal-title'>Upload Year:</h5>
                            <br />
                            <MultiRangeSlider
                                min={filterValues[0]}
                                max={filterValues[1]}
                                onChange={({ min, max }) => {
                                    setMinYear(min);
                                    setMaxYear(max);
                                }}
                            />
                        </div>
                        <div className='modal-body '>
                            <h5 className='modal-title mt-4'>Duration:</h5>
                            <div className='row mt-2 mx-2'>
                                <div className='radio'>
                                    {radioOptions.map((Option) => {
                                        return (
                                            <div className='col d-flex my-1' key={Option.value}>
                                                <input
                                                    className='mr-2'
                                                    type='radio'
                                                    value={Option.value}
                                                    name='duration'
                                                    style={{ transform: "scale(1.5)" }}
                                                    checked={videoDuration === Option.value}
                                                    onChange={(e) => {
                                                        setVideoDuration(e.target.value);
                                                    }}
                                                />
                                                <p className='m-0' style={{ fontSize: "18px" }}>
                                                    {Option.label}
                                                </p>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                        <div className='modal-footer'>
                            <button type='button' className='btn btn-secondary' onClick={() => handlereset()}>
                                Reset
                            </button>
                            <button type='button' className='btn btn-secondary' style={{ background: "#3a3a8f" }} onClick={handleFilterApply}>
                                Apply
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {/* Modal Backdrop */}
            <div className='modal-backdrop fade show'></div>
        </>
    );
};

export default FilterPopUp;
