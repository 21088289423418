import React, { useState } from "react";
import {
  Modal,
  Button,
  Form,
  InputGroup,
  FormControl,
  Nav,
  Tab,
} from "react-bootstrap";
import upload from "../../assets/upload.png";
import { faL } from "@fortawesome/free-solid-svg-icons";

const FileChange = ({
  modalShow,
  selectedImage,
  handleFileChange,
  handleDragFileChange,
  uploadedFile,
  closeModal,
  handleUpload,
  newImageSrc,
  handleReplaceImage,
  selectedTagName,
  newImageUrlChange,
  searchQuery,
  handleSearchChange,
  handleSearchClick,
  imageResults,
  libraryLoading,
  contents
}) => {
  const [key, setKey] = useState("upload");
  const [newSelectedImage, setNewSelectedImage] = useState(selectedImage);

  // Update the new selected image
  const handleImageSelect = (image) => {
    newImageUrlChange(image);
    setKey("search");
  };
  const handleEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const timeAgo = (dateString) => {
    const now = new Date();
    const past = new Date(dateString);
    const diffMs = now - past;
  
    const seconds = Math.floor(diffMs / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const months = Math.floor(days / 30);
    const years = Math.floor(days / 365);
  
    if (years > 0) return `${years} year${years > 1 ? 's' : ''} ago`;
    if (months > 0) return `${months} month${months > 1 ? 's' : ''} ago`;
    if (days > 0) return `${days} day${days > 1 ? 's' : ''} ago`;
    if (hours > 0) return `${hours} hour${hours > 1 ? 's' : ''} ago`;
    if (minutes > 0) return `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
    return `Just now`;
  }

  const formatViews = (views) => {
    if (views >= 1_000_000) {
      return `${(views / 1_000_000).toFixed(1).replace(/\.0$/, '')}M views`;
    }
    if (views >= 1_000) {
      return `${(views / 1_000).toFixed(1).replace(/\.0$/, '')}K views`;
    }
    return `${views} views`;
  }

  return (
    <Modal
      show={modalShow}
      onHide={closeModal}
      fullscreen
      dialogClassName="custom-modal"
      bsClass="my-modal"
    >
      <Modal.Header>
        <Modal.Title>
          Replace{" "}
          {selectedTagName == "img"
            ? "Image"
            : selectedTagName == "iframe" && "Youtube Video"}{" "}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="d-flex row">
        {/* Left Section */}
        <div
          className="flex-fill me-3 col-md-6"
          style={{ borderRight: "2px dashed #3a3a8f" }}
        >
          <div className="mb-4 ">
            <p>
              <strong>
                Selected{" "}
                {selectedTagName == "img"
                  ? "Image"
                  : selectedTagName == "iframe" && <>Youtube Video</>}
              </strong>
            </p>
            <div
              className="d-flex align-items-center justify-content-center row pt-5 mt-5"
              style={{ minHeight: "100%" }}
            >
              <div
                className={`${
                  newImageSrc ? "col-md-5" : "col-md-12 text-center"
                }`}
              >
                {selectedTagName === "img" ? (
                  <img
                    src={selectedImage}
                    alt="Selected"
                    className="img-fluid rounded shadow-sm mb-3"
                    style={{ width: "336px", height: "250px" }}
                  />
                ) : (
                  <div
                    className="iframe-container"
                    style={{
                      position: "relative",
                      width: "100%",
                      paddingTop: "56.25%", // 16:9 aspect ratio (9/16 = 0.5625)
                      overflow: "hidden",
                      borderRadius: "8px",
                      boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                      marginBottom: "1rem",
                    }}
                  >
                    <iframe
                      src={selectedImage}
                      title="iframe"
                      className="rounded shadow-sm"
                      style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        border: "none",
                      }}
                    ></iframe>
                  </div>
                )}
              </div>

              {newImageSrc && (
                <>
                  <span
                    style={{
                      margin: "0 15px",
                      fontSize: "24px",
                      fontWeight: "bold",
                      color: "#007bff",
                    }}
                  >
                    →
                  </span>
                  <div className="d-flex flex-column align-items-center justify-content-center col-md-5">
                    {selectedTagName === "img" ? (
                      <img
                        src={newImageSrc}
                        alt="New Selected"
                        className="img-fluid rounded shadow-sm mb-3"
                        style={{
                          maxWidth: "100%",
                          height: "auto",
                          border: "2px solid #3a3a8f",
                          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                          borderRadius: "12px",
                        }}
                      />
                    ) : (
                      <div
                        className="iframe-container"
                        style={{
                          position: "relative",
                          width: "100%",
                          paddingTop: "56.25%", // 16:9 aspect ratio (9/16 = 0.5625)
                          overflow: "hidden",
                          borderRadius: "8px",
                          boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                          marginBottom: "1rem",
                        }}
                      >
                        <iframe
                          src={newImageSrc}
                          title="iframe"
                          className="rounded shadow-sm"
                          style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            width: "100%",
                            height: "100%",
                            border: "none",
                          }}
                        ></iframe>
                      </div>
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>

        {/* Right Section */}
        <div className="vr"></div>
        <div className="flex-fill col-md-6 " style={{overflow: "none", overflowY: "scroll"}}>
          {selectedTagName == "img" ? (
            <Tab.Container activeKey={key} onSelect={(k) => setKey(k)}>
              <Nav variant="tabs" className="mb-3">
                <Nav.Item>
                  <Nav.Link
                    eventKey="suggesstions"
                    style={{ color: "#3a3a8f" }}
                  >
                    Suggested Images
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="upload" style={{ color: "#3a3a8f" }}>
                    Custom Upload
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="url" style={{ color: "#3a3a8f" }}>
                    Custom URL
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="search" style={{ color: "#3a3a8f" }}>
                    Image Search
                  </Nav.Link>
                </Nav.Item>
              </Nav>
              <Tab.Content className="">
                <Tab.Pane eventKey="suggesstions"></Tab.Pane>

                <Tab.Pane eventKey="upload" className="pt-5 mt-3">
                  <Form>
                    <div
                      className="rounded shadow-none d-flex justify-content-center align-items-center flex-column p-3 imageHover pt-5 mt-6"
                      onDragEnter={(e) => handleEnter(e)}
                      onDragLeave={(e) => handleLeave(e)}
                      onDragOver={(e) => handleOver(e)}
                      onDrop={(e) => handleDragFileChange(e)}
                      style={{
                        border: `${true ? "1px dashed black" : "none"}`,
                        textAlign: "center",
                        backgroundImage: `url(${true ? "" : ""})`,
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "contain",
                        width: "100%",
                        height: "300px",
                      }}
                    >
                      <div>
                        <img
                          src={upload}
                          alt="file upload"
                          style={{ width: "150px", height: "150px" }}
                        />
                        <p>Drag and Drop image here</p>
                        <p>-OR-</p>
                        <div
                          className="d-flex flex-column justify-content-center align-items-center w-100"
                          style={{
                            position: "relative",
                            display: "inline-block",
                          }}
                        >
                          <input
                            type="file"
                            name="imageInput"
                            id="inputFile"
                            accept="image/*"
                            style={{ display: "none" }}
                            required
                            onChange={(e) => {
                              handleFileChange(e);
                              setNewSelectedImage(
                                URL.createObjectURL(e.target.files[0])
                              ); // Preview the selected file
                            }}
                          />
                          <label
                            htmlFor="inputFile"
                            className="btn btn-primary"
                            style={{ backgroundColor: "#3a3a8f" }}
                          >
                            Browse
                          </label>
                        </div>
                      </div>

                      {/* <Form.Group controlId="formFile" className="mb-3">
                      <Form.Label>Choose an image to upload</Form.Label>
                      <Form.Control
                        type="file"
                        accept="image/*"
                        onChange={(e) => {
                          handleFileChange(e);
                          setNewSelectedImage(
                            URL.createObjectURL(e.target.files[0])
                          ); // Preview the selected file
                        }}
                      />
                    </Form.Group> */}
                    </div>

                    {uploadedFile && (
                      <div className="mt-3">
                        <p>
                          <strong>Selected File:</strong> {uploadedFile.name}
                        </p>
                        <Button
                          variant="primary"
                          onClick={handleUpload}
                          className="me-2"
                          style={{ backgroundColor: "#3a3a8f" }}
                        >
                          Upload
                        </Button>
                      </div>
                    )}
                  </Form>
                </Tab.Pane>

                <Tab.Pane eventKey="url">
                  <Form.Group controlId="formUrl" className="mt-4">
                    <Form.Label>Paste Image URL</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter image URL"
                      onChange={(e) => {
                        newImageUrlChange(e.target.value);
                        setNewSelectedImage(e.target.value); // Update selected image from URL
                      }}
                    />
                  </Form.Group>
                </Tab.Pane>

                <Tab.Pane eventKey="search">
                  <InputGroup className="mb-3">
                    <FormControl
                      placeholder="Search for images"
                      aria-label="Search"
                      value={searchQuery}
                      onChange={handleSearchChange}
                    />
                    <Button
                      variant="outline-secondary"
                      onClick={handleSearchClick}
                    >
                      Search
                    </Button>
                  </InputGroup>

                  {imageResults && (
                    <div
                      style={{
                        // minHeight: "100px",
                        maxHeight: "450px",
                        overflowY: "auto",
                      }}
                      className="row g-3"
                    >
                      {imageResults.map((image, index) => (
                        <div key={index} className="col-md-4">
                          <div
                            onClick={() => handleImageSelect(image)}
                            style={{
                              width: "100%",
                              height: "auto",
                              // position: "relative",
                              borderRadius: "8px",
                              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                            }}
                          >
                            <img
                              src={image}
                              alt={`Result ${index}`}
                              className="img-fluid img-thumbnail"
                              style={{
                                width: "100%",
                                height: "auto",
                                borderRadius: "8px",
                              }}
                            />
                          </div>
                        </div>
                      ))}
                    </div>
                  )}

                  {libraryLoading && (
                    <div className="container-loader">
                      <span></span>
                      <span></span>
                      <span></span>
                      <span></span>
                    </div>
                  )}
                </Tab.Pane>
                {/* {newImageSrc && (
                <div className="">
                  <strong>New Image Uploaded:</strong>
                  <img
                    src={newImageSrc}
                    width={"200px"}
                    height={"150px"}
                    alt="kjhgf"
                  />
                  <button onClick={() => newImageUrlChange("")}>Delete</button>
                </div>
              )} */}
              </Tab.Content>
            </Tab.Container>
          ) : (
            selectedTagName == "iframe" && (<>
              <Form.Group controlId="formUrl" className="mt-4">
                <Form.Label style={{ fontWeight: "bold", color: "#333" }}>
                  Paste YouTube URL
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter YouTube URL"
                  onChange={(e) => {
                    const url = e.target.value;
                    // if (!validateYouTubeUrl(url)) {
                    //   alert("Please enter a valid YouTube link.");
                    //   return;
                    // }
                    newImageUrlChange(url);
                    setNewSelectedImage(url);
                  }}
                  style={{
                    borderRadius: "8px",
                    border: "2px solid #007bff",
                    padding: "10px",
                  }}
                />
              </Form.Group>
              <p className="text-center my-2" style={{fontSize: "14px", fontWeight: "bold", color: "#2A2A72"}}>-OR-</p>
              <div className="youtube-job-aids">
                <p className="mb-4" style={{fontWeight: "bold", color: "#333"}}>Select Any Job Aids to Replace With</p>
                <div>
                  {contents.map((content)=>(
                    content.YoutubeJobAids.map((jobAids) =>(
                      <div className="d-flex items-start p-2 border rounded shadow-sm my-4" 
                      role="button"
                      onClick={()=>{
                        newImageUrlChange(jobAids.URL)
                        setNewSelectedImage(jobAids.URL)
                      }}
                      >
                        <div className="embed-responsive embed-responsive-16by9 col-6">
                          <iframe className="embed-responsive-item" src={jobAids.URL}></iframe>
                        </div>
                        
                        {/* Right side: Video Info */}
                        <div className="col-6">
                          <p className="" style={{fontWeight: "bold", color: "#333"}}>{jobAids.title}</p>
                          <p className="text-gray-600 d-flex justify-content-start" style={{fontSize: '12px'}}> <span>{formatViews(jobAids.view_count)}</span><span className="span-before">{timeAgo(jobAids.released_date)}</span></p>
                        </div>
                      </div>
                    ))
                  ))}
                </div>
              </div>

              </>
            )
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="primary"
          onClick={handleReplaceImage}
          className="me-2"
          style={{ backgroundColor: "#3a3a8f" }}
        >
          Replace
        </Button>
        <Button variant="secondary" onClick={closeModal}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default FileChange;
