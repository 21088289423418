import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";

const CourseList = ({ results }) => {
    const navigate = useNavigate();
    const [downloadCliked, setDownloadClicked] = useState(false);
    const BASE_URL = process.env.REACT_APP_API_KEY;
    const handleClick = (id) => {
        navigate(`/coursedata/internal/${id}`);
    };
    const handlePreviewClick = (id) => {
        window.open(`/course/${id}`, "_blank");
    };
    const handleDownloadClick = async (id, title) => {
        try {
            setDownloadClicked(true);
            const response = await axios.post(
                `${BASE_URL}/download`,
                {
                    uuid: id,
                },
                {
                    responseType: "blob", // Important: Receive binary data
                }
            );

            // Extract filename from Content-Disposition header
            const contentDisposition = response.headers["content-disposition"];
            let filename = `${title}.zip`; // Fallback filename

            if (contentDisposition && contentDisposition.includes("filename=")) {
                // This regex handles possible UTF-8 encoding and quotes.
                const match = contentDisposition.match(/filename\*?=(?:UTF-8'')?["']?([^"';\n]+)/i);
                if (match && match[1]) {
                    filename = match[1];
                }
            }

            // Create a URL for the blob and trigger a download via a temporary link
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const a = document.createElement("a");
            a.href = url;
            a.download = filename;
            document.body.appendChild(a);
            a.click();
            a.remove();
            window.URL.revokeObjectURL(url);
            setDownloadClicked(false);
        } catch (error) {
            setDownloadClicked(false);
            toast.error("Download Failed Invalid Download");
        }
    };
    return (
        <>
            <div id='image_grid' className='container-fluid ml-5 mt-5'>
                {results.length ? (
                    results?.map((article, i) => (
                        <>
                            {/* <div id="article_div" className="shadow bg-white rounded" key={i}>
              <div onClick={() => handleClick(article.uuid)}>
                <div className="p-5" title={article.courseTitle}>
                  <div>
                    <div id="article_headline">
                      <h5>
                        {article.courseTitle
                          ? article.courseTitle.charAt(0).toUpperCase() +
                            article.courseTitle.slice(1, 50) +
                            (article.courseTitle.length > 50 ? "..." : "")
                          : ""}
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}

                            <div id='article_div' className='shadow bg-white rounded m-2' key={i} style={{ position: "relative" }}>
                                <div onClick={() => handleClick(article.uuid)}>
                                    <div className='p-5' title={article.courseTitle}>
                                        <h5 style={{ marginBottom: "40px" }}>
                                            {article.courseTitle
                                                ? article.courseTitle.charAt(0).toUpperCase() +
                                                  article.courseTitle.slice(1, 50) +
                                                  (article.courseTitle.length > 50 ? "..." : "")
                                                : ""}
                                        </h5>
                                    </div>
                                </div>
                                <button
                                    className='preview-button mr-4 pb-2'
                                    title='Download'
                                    onClick={(e) => {
                                        e.stopPropagation(); // Prevent triggering the parent div's `onClick`
                                        handleDownloadClick(article.uuid, article.courseTitle);
                                    }}
                                    style={{
                                        position: "absolute",
                                        bottom: "10px",
                                        right: "50px",
                                        background: "none",
                                        border: "none",
                                        cursor: "pointer",
                                        color: " #3a3a8f",
                                        padding: "0",
                                        outline: "none",
                                    }}
                                >
                                    <i className='fas fa-download'></i>
                                </button>
                                <button
                                    className='preview-button mr-4 pb-2'
                                    title='Preview'
                                    onClick={(e) => {
                                        e.stopPropagation(); // Prevent triggering the parent div's `onClick`
                                        handlePreviewClick(article.uuid);
                                    }}
                                    style={{
                                        position: "absolute",
                                        bottom: "10px",
                                        right: "10px",
                                        background: "none",
                                        border: "none",
                                        cursor: "pointer",
                                        color: " #3a3a8f",
                                        padding: "0",
                                        outline: "none",
                                    }}
                                >
                                    <i className='fas fa-eye'></i>
                                </button>
                            </div>
                        </>
                    ))
                ) : (
                    <p className='m-5 text-center'>No Courses Found</p>
                )}
            </div>
            {downloadCliked && (
                <div
                    style={{
                        position: "fixed",
                        left: "48%",
                        top: "50%",
                        transform: "transition(-50%, -50%)",
                        zIndex: 3,
                        width: "3rem",
                        height: "3rem",
                    }}
                >
                    <div className='spinner-border' role='status' style={{ position: "sticky" }}>
                        <span className='sr-only'>Loading...</span>
                    </div>
                </div>
            )}
        </>
    );
};

export default CourseList;
