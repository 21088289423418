import React from "react";

const CourseContent = ({
    lessonTitle,
    description,
    keys,
    detailDescription,
    contentData,
}) => {
    console.log(contentData);

    return (
        <div className={`col px-5 my-3 w-100 d-flex flex-column justify-content-center`} style={{backgroundColor: '#fff'}} key={keys}>
            <div>
                <h2 className="mb-2 topic-title thesans7" >{lessonTitle}</h2>
                <p className="detail-description thesans" style={{color: '#000', lineHeight: 1.9411764706}}>{description}</p>
            </div>
            <div
                style={{
                    wordBreak: "break-word",   
                    color: '#000',
                    lineHeight: 1.9411764706                    // Breaks long words if necessary
                }}
                className="detail-description thesans"
                dangerouslySetInnerHTML={{
                    __html: detailDescription,
                }}
            />
        </div>
    );
};

export default CourseContent;
