import React, { useState } from "react";
import styled from "styled-components";
import CourseList from "./CourseList";
import FilterPopUp from "./helpers/FilterPopUp";

// Styled container for the entire search component
const SearchContainer = styled.div`
    display: flex;
    flex-direction: column;
    // justify-content: center;
    align-items: center;
    // height: 100vh;
`;

// Search input bar
const SearchInput = styled.textarea`
    width: 70%;
    padding: 12px 20px;
    margin-bottom: 0px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    box-sizing: border-box;
    outline: none;

    &:focus {
        border-color: #3a3a8f;
    }

    @media (max-width: 768px) {
        width: 90%;
    }
`;

// Button for submitting the search
const SearchButton = styled.button`
    padding: 12px 20px;
    background-color: #3a3a8f;
    color: white;
    font-size: 16px;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    width: 90%;
    font-weight: bold;
    margin-right: 2px;

    &:hover {
        background-color: #2b2b6d;
    }

    @media (max-width: 768px) {
        width: 90%;
    }
`;

// Loading spinner
const LoadingSpinner = styled.div`
    margin-top: 20px;
    font-size: 1.5em;
    color: #3a3a8f;
`;

const SearchBarComponent = ({ handleSearchChange, loading, query, handleSearchSubmit, courselist, handleGenerateParameter, filterValues }) => {
    const [filterApplied, setFilterApplied] = useState(false);
    const radioOptions = { less4_relevance: "Under 4 minutes", "4to20_relevance": "4 to 20 minutes", greater20_relevance: "Over 20 minutes" };

    const handleEnter = (event) => {
        if (event.key === "Enter" && query) {
            handleSearchSubmit();
        }
    };
    const [popUpShow, setPopUpShow] = useState(false);

    const handleFilterApplied = (value) => {
        setFilterApplied(value);
    };

    const handleOverlayClick = () => {
        setPopUpShow(false);
    };

    return (
        <>
            <SearchContainer>
                <h2 className='m-3'>India AI</h2>
                {/* <div className='d-xl-inline-flex'> */}
                <SearchInput
                    type='text'
                    value={query}
                    onChange={handleSearchChange}
                    onKeyDown={handleEnter}
                    placeholder='Enter query to generate course'
                />
                {filterApplied && (
                    <div className='container px-0 justify-content-end' style={{ width: "70%" }}>
                        <span className='ml-2 my-2 px-2 mx-0' style={{ border: "1px solid #3a3a8f", borderRadius: "10px", color: "#3a3a8f" }}>
                            From: {filterValues[0] ?? "current"} Years
                        </span>
                        <span className='ml-2 my-2 px-2 mx-0' style={{ border: "1px solid #3a3a8f", borderRadius: "10px", color: "#3a3a8f" }}>
                            To: {filterValues[1]} Year
                        </span>
                        <div className='ml-2 my-2 px-2 mx-0' style={{ border: "1px solid #3a3a8f", borderRadius: "10px", color: "#3a3a8f" }}>
                            Duration: {filterValues[2] ? radioOptions[filterValues[2]] : "default"}
                        </div>
                    </div>
                )}
                {!filterApplied && <div style={{ height: "15px" }}></div>}
                <div className='d-flex justify-content-between' style={{ width: "70%" }}>
                    <SearchButton onClick={handleSearchSubmit}>Generate</SearchButton>
                    <button
                        className='custom-filter-btn btn btn-secondary px-3'
                        type='button'
                        data-bs-toggle='dropdown'
                        aria-expanded='false'
                        style={{
                            background: "None",
                            color: "#3a3a8f",
                            borderRadius: "10px",
                            borderWidth: "3px",
                            borderColor: "#3a3a8f",
                            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                            fontWeight: "bold",
                        }}
                        onMouseOver={(e) => {
                            e.currentTarget.style.background = "#e8e8ff";
                            e.currentTarget.style.transform = "translateY(0px)";
                            e.currentTarget.style.boxShadow = "0 6px 8px rgba(0, 0, 0, 0.15)";
                        }}
                        onMouseOut={(e) => {
                            e.currentTarget.style.background = "None";
                            e.currentTarget.style.transform = "translateY(0)";
                            e.currentTarget.style.boxShadow = "0 4px 6px rgba(0, 0, 0, 0.1)";
                        }}
                        onMouseDown={(e) => {
                            e.currentTarget.style.background = "#d8d8ff";
                            e.currentTarget.style.transform = "translateY(1px)";
                            e.currentTarget.style.boxShadow = "0 2px 4px rgba(0, 0, 0, 0.1)";
                        }}
                        onMouseUp={(e) => {
                            e.currentTarget.style.background = "#e8e8ff";
                            e.currentTarget.style.transform = "translateY(0px)";
                            e.currentTarget.style.boxShadow = "0 6px 8px rgba(0, 0, 0, 0.15)";
                        }}
                        onClick={() => setPopUpShow(true)}
                    >
                        <i className='fas fa-filter mr-2'></i>
                        Filter
                    </button>
                    {popUpShow && (
                        <FilterPopUp
                            handleOverlayClick={handleOverlayClick}
                            handleGenerateParameter={handleGenerateParameter}
                            filterValues={filterValues}
                            handleFilterApplied={handleFilterApplied}
                        />
                    )}
                </div>
                {loading && <LoadingSpinner>Loading...</LoadingSpinner>}
            </SearchContainer>

            <CourseList results={courselist} />
        </>
    );
};

export default SearchBarComponent;
