// import "./Prompt.css";
import React, { useState, useEffect, version } from "react";
import Axios from "axios";
import { toast } from "react-toastify";
import {
  faChevronCircleLeft,
  faChevronCircleRight,
  faCirclePlus,
  faPenToSquare,
  faPen,
} from "@fortawesome/free-solid-svg-icons";
import "./Prompt.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";


function Prompt() {
  const [prompts, setPrompts] = useState([]);
  const [currentPrompt, setCurrentPrompt] = useState({});
  const [userPrompt, setUserPrompt] = useState("");
  const [active, setActive] = useState(false);
  const [promptTitleIndex, setPromptTitleIndex] = useState(0);
  const [currentTab, setCurrentTab] = useState("prompt");
  const [promptVersion, setPromptVersion] = useState(0);
  const [createNewVariable, setCreateNewVariable] = useState(false);
  const [variableFormData, setVariableFormData] = useState({
    variable_name: "",
    variable_value: "",
  });
  const [variableFormDatas, setVariableFormDatas] = useState([]);
  const [addNewPrompt, setAddNewPrompt] = useState(false);
  const [addNewPromptContent, setAddNewPromptContent] = useState({
    prompt_title: "",
    base_content: "",
  });
  const BASE_URL = process.env.REACT_APP_API_KEY;

  const fetchNewPromptData = () => {
    axios
      .get(`${BASE_URL}/getprompt/${prompts[promptTitleIndex]?._id}`)
      .then((response) => {
        if (response.data.prompt && response.data.prompt.base_content_active) {
          setUserPrompt(response.data.prompt.base_content);
          setPromptVersion(0);
          setCurrentPrompt(response.data.prompt);
          setVariableFormDatas(
            response.data.prompt.variables.length > 0
              ? response.data.prompt.variables
              : []
          );
        } else if (response.data.prompt && response.data.prompt.versions) {
          const versions = Object.values(response.data.prompt.versions);
          const version_no = versions.findIndex(
            (version) => version.active === true
          );
          setActive(true);
          setUserPrompt(versions[version_no]?.content);
          setPromptVersion(version_no !== -1 ? version_no + 1 : 0);
          setCurrentPrompt(response.data.prompt);
          setVariableFormDatas(
            response.data.prompt.variables.length > 0
              ? response.data.prompt.variables
              : []
          );
        }
      });
  };

  useEffect(() => {
    axios
      .get(`${BASE_URL}/getpromptlist`)
      .then((response) => {
        setPrompts(response.data.prompts);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    axios.get(`${BASE_URL}/getprompt/${prompts[0]?._id}`).then((response) => {
      if (
        response?.data?.prompt &&
        response?.data?.prompt?.base_content_active
      ) {
        console.log(response.data.prompt.base_content);
        setUserPrompt(response.data.prompt?.base_content || "");
        setPromptVersion(0);
        setCurrentPrompt(response.data.prompt);
        setVariableFormDatas(response.data.prompt.variables);
      } else if (response?.data?.prompt) {
        const versions =
          response?.data?.prompt?.versions &&
          Object.values(response?.data?.prompt?.versions);
        const version_no =
          versions && versions.findIndex((version) => version.active === true);
        setActive(true);
        setUserPrompt(versions?.[version_no]?.content || "");
        setPromptVersion(version_no !== -1 ? version_no + 1 : 0);
        setCurrentPrompt(response.data.prompt);
        setVariableFormDatas(response.data.prompt.variables);
      }
    });
  }, [prompts]);

  useEffect(() => {
    axios
      .get(`${BASE_URL}/getprompt/${prompts[promptTitleIndex]?._id}`)
      .then((response) => {
        if (response.data.prompt && response.data.prompt.base_content_active) {
          setUserPrompt(response.data.prompt.base_content);
          setPromptVersion(0);
          setCurrentPrompt(response.data.prompt);
          setVariableFormDatas(
            response.data.prompt.variables.length > 0
              ? response.data.prompt.variables
              : []
          );
        } else if (response.data.prompt && response.data.prompt.versions) {
          const versions = Object.values(response.data.prompt.versions);
          const version_no = versions.findIndex(
            (version) => version.active === true
          );
          setActive(true);
          setUserPrompt(versions[version_no]?.content);
          setPromptVersion(version_no !== -1 ? version_no + 1 : 0);
          setCurrentPrompt(response.data.prompt);
          setVariableFormDatas(
            response.data.prompt.variables.length > 0
              ? response.data.prompt.variables
              : []
          );
        }
      });
  }, [promptTitleIndex]);

  const handleUserPromptChange = (event) => {
    setUserPrompt(event.target.value);
  };

  const handlePromptActive = () => {
    var currentPromptData = currentPrompt;
    var versions = Object.entries(currentPromptData.versions);
    versions = versions.map(([key, version], index) => {
      if (version.active) return [key, { ...version, active: false }];
      if (index + 1 === promptVersion)
        return [key, { ...version, active: true }];
      return [key, version];
    });
    currentPromptData.versions = Object.fromEntries(versions);
    currentPromptData = { ...currentPromptData, base_content_active: false };

    axios
      .put(`${BASE_URL}/updateprompt/${currentPrompt._id}`, { currentPromptData })
      .then((response) => {
        if (response.data.msg) {
          console.log(currentPromptData);
          setCurrentPrompt(currentPromptData);
          setActive(true);
          toast.success("Prompt version activated successfully");
        }
      })
      .catch((error) => {
        toast.error("Couldnt update the version");
      });
  };

  const handlePromptInActive = () => {
    var currentPromptData = currentPrompt;
    var versions = Object.entries(currentPromptData.versions);
    var base_content_active = true;
    versions = versions.map(([key, version], index) => {
      if (version.active && index + 1 === promptVersion)
        return [key, { ...version, active: false }];
      if (version.active && index + 1 !== promptVersion)
        base_content_active = false;

      return [key, version];
    });
    currentPromptData.versions = Object.fromEntries(versions);
    currentPromptData = {
      ...currentPromptData,
      base_content_active: base_content_active,
    };
    console.log(currentPromptData);
    axios
      .put(`${BASE_URL}/updateprompt/${currentPrompt._id}`, { currentPromptData })
      .then((response) => {
        if (response.data.msg) {
          console.log(currentPromptData.versions);
          setCurrentPrompt(currentPromptData);
          setActive(false);
          toast.success("Prompt version deactivated successfully");
        }
      })
      .catch((error) => {
        toast.error("Couldnt update the version");
      });
  };

  const handleSideNavItems = (index) => {
    setPromptTitleIndex(index);
  };

  const handlePromptVersion = (versionNo) => {
    setPromptVersion(versionNo);
    if (versionNo !== 0) {
      console.log(currentPrompt.versions[`v${versionNo}`]);

      if (currentPrompt.versions[`v${versionNo}`].active) setActive(true);
      else setActive(false);
      setUserPrompt(currentPrompt.versions[`v${versionNo}`].content);
    } else setUserPrompt(currentPrompt.base_content);
  };

  const handleSubmitNewPrompt = () => {
    var currentPromptData = currentPrompt;
    var versions = Object.entries(currentPromptData.versions);
    versions = versions.map(([key, version], index) => {
      if (index + 1 === promptVersion)
        return [key, { ...version, content: userPrompt }];

      return [key, version];
    });
    currentPromptData.versions = Object.fromEntries(versions);

    axios
      .put(`${BASE_URL}/updateprompt/${currentPrompt._id}`, { currentPromptData })
      .then((response) => {
        if (response.data.msg) {
          console.log(currentPromptData.versions);
          setCurrentPrompt(currentPromptData);
          toast.success("Promp updated successfully");
        }
      })
      .catch((error) => {
        toast.error("Couldnt update the prompt");
      });
  };

  const handlePromptNavigation = (move) => {
    if (move === "prev" && promptTitleIndex - 1 >= 0) {
      setPromptTitleIndex(promptTitleIndex - 1);
    } else if (move === "next" && promptTitleIndex + 1 < prompts.length) {
      setPromptTitleIndex(promptTitleIndex + 1);
    }
  };

  const handleNavTabs = (tab) => {
    if (currentTab !== tab) {
      setCurrentTab(tab);
    }
  };

  const handleCreateNewVariable = () => {
    setCreateNewVariable(true);
  };

  const handleVariableFormDataChange = (event) => {
    const { name, value } = event.target;
    setVariableFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };

  const handleVariableFormSubmit = (event) => {
    event.preventDefault();

    var currentPromptData = currentPrompt;
    var variableNameExist = false;
    currentPromptData.variables.forEach((variable) => {
      Object.entries(variable).forEach(([key, value]) => {
        if (key === variableFormData.variable_name) {
          variableNameExist = true;
          return toast.error("Variable name already exists");
        }

      });
    });

    if (!variableNameExist) {
      const matches = variableFormData.variable_name.match(/\$/g);
      if (matches === null || matches.length !== 2) {
        return toast.error("Variable name should contain '$' ");
      }
      const variableData = {
        [variableFormData.variable_name]: variableFormData.variable_value,
      };
      currentPromptData.variables = [
        ...currentPromptData.variables,
        variableData,
      ];
      axios
        .put(`${BASE_URL}/updateprompt/${currentPromptData._id}`, {
          currentPromptData,
        })
        .then((response) => {
          if (response.data.msg) {
            setVariableFormData({ variable_name: "", variable_value: "" });
            setCreateNewVariable(false);
            fetchNewPromptData();
            toast.success("variable saved successfully");
          }
        })
        .catch((error) => {
          toast.error(`unable to add new variable`);
          console.error(error);
        });
    }
  };

  const handleAddNewPromptChange = (event) => {
    const { name, value } = event.target;
    setAddNewPromptContent((promptContent) => ({
      ...promptContent,
      [name]: value,
    }));
  };

  const handleAddNewPrompt = () => {
    if (addNewPrompt.prompt_title === "") {
      toast.error("Prompt Title can't be empty");
      return;
    } else if (addNewPrompt.base_content === "") {
      toast.error("Base Content can't be empty");
      return;
    }

    axios
      .post(`${BASE_URL}/createPromptOutline`, addNewPromptContent)
      .then((response) => {
        if (response.data.msg) {
          toast.success("New Prompt added successfully");
          setAddNewPrompt(false)
          setAddNewPromptContent({ prompt_title: "", base_content: "" });
        } else {
          toast.error("something went wrong");
        }
      })
      .catch((error) => {
        toast.error("something went wrong");
      });
  };

  const NavItem = ({ title, active, val }) => {
    return (
      <div
        className={`p-3 my-2 d-flex align-items-center nav-item ${active ? "active-nav" : ""
          }`}
        role="link"
        onClick={() => handleSideNavItems(val)}
        style={{ cursor: "pointer" }}
      >
        <div className="text-light" style={{ fontSize: "16px" }}>
          {title.charAt(0).toUpperCase()}
          {title.slice(1)}
        </div>
      </div>
    );
  };

  const SideNav = () => {
    return (
      <>
        {prompts.length && (
          <div
            className="container-fluid"
            style={{
              overflow: "scroll",
              overflowX: "hidden",
              height: "100vh",
              backgroundColor: "#00004B",
            }}
          >
            <div className="d-flex justify-content-between my-3 align-items-center">
              <h3 style={{ color: "#fff", margin: "0px" }}>LLM Prompts</h3>
              <div>
                <FontAwesomeIcon
                  icon={faCirclePlus}
                  style={{ color: "#fff", fontSize: "18px", cursor: "pointer" }}
                  title="Add New Prompt"
                  onClick={() => setAddNewPrompt(true)}
                />
              </div>
            </div>
            <div>
              {prompts.map((prompt, index) => {
                const formattedPrompt = prompt.prompt_title || "Default Title"; // Optional fallback in case title is undefined
                return (
                  <NavItem
                    title={formattedPrompt}
                    key={index}
                    val={index}
                    active={index === promptTitleIndex}
                  />
                );
              })}
            </div>
          </div>
        )}
      </>
    );
  };

  const VariableDisplayAndEdit = ({ variable_name, variable_value, key }) => {
    const [editVariableValue, setEditVariableValue] = useState(false);
    const [editValue, setEditValue] = useState(variable_value);
    const handleVariableValueChange = (event) => {
      setEditValue(event.target.value);
    };

    const handleVariableUpdate = () => {
      const variableData = { [variable_name]: editValue };
      var currentPromptData = currentPrompt;
      var variables = currentPromptData.variables.filter(
        (variable) => !Object.keys(variable).includes(variable_name)
      );
      currentPromptData.variables = [...variables, variableData];
      axios
        .put(`${BASE_URL}/updateprompt/${currentPromptData._id}`, {
          currentPromptData,
        })
        .then((response) => {
          if (response.data.msg) {
            setVariableFormData({ variable_name: "", variable_value: "" });
            setEditVariableValue(false);
            fetchNewPromptData();
            toast.success("variable updated successfully");
          }
        })
        .catch((error) => {
          toast.error(`unable to edit variable`);
          console.error(error);
        });
    };

    return (
      <div className="d-flex justify-content-between my-2">
        <div
          className="d-flex align-items-center p-2 mr-2 rounded shadow-none"
          style={{ width: "30%", backgroundColor: "#d3d3d3" }}
        >
          {variable_name}
        </div>

        <div
          className="p-2 rounded shadow-none"
          style={{
            position: "relative",
            width: "70%",
            backgroundColor: "#d3d3d3",
          }}
        >
          {!editVariableValue && variable_value}
          {!editVariableValue && (
            <FontAwesomeIcon
              icon={faPenToSquare}
              style={{
                position: "absolute",
                top: "10px",
                right: "10px",
                fontSize: "16px",
                cursor: "pointer",
              }}
              onClick={() => setEditVariableValue(true)}
            />
          )}
          {editVariableValue && (
            <div className="mt-2">
              <textarea
                type="text"
                className="form-control"
                value={editValue}
                style={{ display: "block", outline: "none", resize: "none" }}
                onChange={(event) =>
                  handleVariableValueChange(event, variable_name)
                }
              />
              <div className="d-flex justify-content-end mt-2">
                <button
                  type="button"
                  className="bg-danger p-1 mx-2"
                  style={{
                    width: "100px",
                    fontSize: "14px",
                    outline: "none",
                    border: "none",
                    color: "#fff",
                    borderRadius: "5px",
                    cursor: "pointer",
                  }}
                  onClick={() => setEditVariableValue(false)}
                >
                  Cancel
                </button>

                <button
                  type="button"
                  className="bg-primary p-1"
                  style={{
                    width: "100px",
                    fontSize: "14px",
                    outline: "none",
                    border: "none",
                    color: "#fff",
                    borderRadius: "5px",
                    cursor: "pointer",
                  }}
                  onClick={handleVariableUpdate}
                >
                  Update
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <>
      {addNewPrompt ? (
        <div className="d-flex justify-content-center my-3">
          <div className="p-3 col-9">
            <div className="">
              <h1>Add New Prompt</h1>
            </div>
            <div className="bg-light w-100 rounded shadow-none p-4">
              <label className="form-label" style={{ fontWeight: "bold" }}>
                Prompt Name
              </label>
              <input
                type="text"
                className="w-100 p-2 form-control my-2"
                name="prompt_title"
                onChange={handleAddNewPromptChange}
                placeholder="Enter Prompt Name"
                style={{ display: "block" }}
              />

              <label className="form-label my-2" style={{ fontWeight: "bold" }}>
                Prompt Base Content
              </label>
              <textarea
                className="w-100 rounded shadow-none form-control p-2 my-2"
                name="base_content"
                onChange={handleAddNewPromptChange}
                style={{ display: "block", resize: "none", height: "300px" }}
              />

              <div className="d-flex w-100 mt-4 justify-content-end">
                <button
                  className="bg-danger p-1 mr-2"
                  style={{
                    width: "100px",
                    fontSize: "14px",
                    outline: "none",
                    border: "none",
                    color: "#fff",
                    borderRadius: "5px",
                  }}
                  onClick={() => setAddNewPrompt(false)}
                >
                  Cancel
                </button>
                <button
                  className="bg-primary p-1 mr-2"
                  style={{
                    width: "100px",
                    fontSize: "14px",
                    outline: "none",
                    border: "none",
                    color: "#fff",
                    borderRadius: "5px",
                  }}
                  onClick={() => handleAddNewPrompt()}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
          {prompts.length && (
            <div className="d-flex">
              <SideNav className="col-3" />
              <div className="col-9 d-flex flex-column container-fluid px-5 py-4">
                <h1>
                  {prompts[promptTitleIndex] &&
                    prompts[promptTitleIndex].prompt_title
                    ? `${prompts[promptTitleIndex].prompt_title}`
                    : "Default Title"}
                </h1>

                <ul className="nav nav-tabs" role="tablist">
                  <li
                    className="nav-item"
                    style={{ background: "transparent" }}
                  >
                    <button
                      className={`nav-link ${currentTab === "prompt" ? "active" : ""
                        }`}
                      onClick={() => handleNavTabs("prompt")}
                      role="tab"
                      data-toggle="tab"
                      aria-selected={currentTab === "prompt"}
                    >
                      Main Prompt
                    </button>
                  </li>
                  <li
                    className="nav-item"
                    style={{ background: "transparent" }}
                  >
                    <button
                      className={`nav-link ${currentTab === "variable" ? "active" : ""
                        }`}
                      onClick={() => handleNavTabs("variable")}
                      role="tab"
                      data-toggle="tab"
                      aria-selected={currentTab === "variable"}
                    >
                      Variables
                    </button>
                  </li>
                </ul>

                {/* PROMPT TAB */}

                {currentTab === "prompt" && (
                  <div className="p-0 m-0">
                    <div
                      className="d-flex flex-column w-100 mt-3 p-0"
                      style={{ position: "relative", height: "400px" }}
                    >
                      <div className="d-flex justify-content-between my-2">
                        {/* PROMPT VERSION BUTTON */}
                        <div>
                          <div>
                            <span
                              className={`badge ${promptVersion === 0
                                ? "badge-primary"
                                : "badge-secondary"
                                } p-2`}
                              role="link"
                              style={{
                                display: "inline-block",
                                marginRight: "5px",
                                fontSize: "12px",
                                width: "auto",
                                cursor: "pointer",
                              }}
                              onClick={() => handlePromptVersion(0)}
                            >
                              Base Version
                            </span>
                            <span
                              className={`badge ${promptVersion === 1
                                ? "badge-primary"
                                : "badge-secondary"
                                } p-2`}
                              role="link"
                              style={{
                                display: "inline-block",
                                marginRight: "5px",
                                fontSize: "12px",
                                width: "75px",
                                cursor: "pointer",
                              }}
                              onClick={() => handlePromptVersion(1)}
                            >
                              Version 1
                            </span>
                            <span
                              className={`badge ${promptVersion === 2
                                ? "badge-primary"
                                : "badge-secondary"
                                } p-2`}
                              role="link"
                              style={{
                                display: "inline-block",
                                marginRight: "5px",
                                fontSize: "12px",
                                width: "75px",
                                cursor: "pointer",
                              }}
                              onClick={() => handlePromptVersion(2)}
                            >
                              Version 2
                            </span>
                            <span
                              className={`badge ${promptVersion === 3
                                ? "badge-primary"
                                : "badge-secondary"
                                } p-2`}
                              role="link"
                              style={{
                                display: "inline-block",
                                marginRight: "5px",
                                fontSize: "12px",
                                width: "75px",
                                cursor: "pointer",
                              }}
                              onClick={() => handlePromptVersion(3)}
                            >
                              Version 3
                            </span>
                          </div>
                          <div
                            className="text-danger mt-1"
                            style={{
                              fontWeight: "bold",
                              fontSize: "14px",
                              display: `${promptVersion !== 0 ? "block" : "none"
                                }`,
                            }}
                          >
                            Variable name should begin and end with '$' EX:
                            $Your_Variable_Name$
                          </div>
                        </div>

                        {/* ACTIVE AND NOT ACTIVE BUTTON
                  This buttons will be only displayed when promptVersion !== 0 */}

                        {promptVersion !== 0 && (
                          <div style={{ width: "150px" }}>
                            <button
                              className={`btn w-50 ${active ? "btn-success" : "btn-outline-success"
                                }`}
                              style={{
                                fontSize: "12px",
                                borderTopRightRadius: "0px",
                                borderBottomRightRadius: "0px",
                                transition:
                                  "background-color 0.3s ease, color 0.3s ease",
                              }}
                              onClick={handlePromptActive}
                            >
                              Active
                            </button>
                            <button
                              className={`btn w-50 ${!active
                                ? "btn-secondary"
                                : "btn-outline-secondary"
                                }`}
                              style={{
                                fontSize: "12px",
                                borderTopLeftRadius: "0px",
                                borderBottomLeftRadius: "0px",
                                transition:
                                  "background-color 0.3s ease, color 0.3s ease",
                              }}
                              onClick={handlePromptInActive}
                            >
                              Inactive
                            </button>
                          </div>
                        )}
                      </div>

                      {/* TEXTAREA  */}

                      <div>
                        <textarea
                          type="text"
                          value={userPrompt}
                          onChange={handleUserPromptChange}
                          className="w-100 m-0 rounded shadow-none p-3"
                          style={{
                            height: "300px",
                            outline: "none",
                            resize: "none",
                          }}
                          readOnly={promptVersion === 0}
                        />
                      </div>

                      {/* RESET AND UPDATE BUTTON
                This buttons will be only displayed when promptVersion !== 0 */}

                      {promptVersion !== 0 && (
                        <div className="mt-2 d-flex justify-content-end">
                          <button
                            type="button"
                            className="btn btn-primary"
                            style={{ display: "inline-block" }}
                            onClick={handleSubmitNewPrompt}
                          >
                            Update Prompt
                          </button>
                        </div>
                      )}
                    </div>

                    {/* PROMPT NAVIGATOR */}

                    <div className="d-flex justify-content-center mt-5">
                      <div
                        className="d-flex justify-content-between align-items-center p-2"
                        style={{ width: "150px" }}
                      >
                        <FontAwesomeIcon
                          icon={faChevronCircleLeft}
                          style={{
                            fontSize: "1.5rem",
                            color: promptTitleIndex === 0 ? "#ddd" : "#000",
                            cursor: "pointer",
                          }}
                          role="link"
                          onClick={() => handlePromptNavigation("prev")}
                        />
                        <div
                          style={{
                            fontSize: "12px",
                            fontWeight: "bold",
                            color: "#000",
                          }}
                        >
                          {promptTitleIndex + 1} to {prompts.length}
                        </div>
                        <FontAwesomeIcon
                          icon={faChevronCircleRight}
                          style={{
                            fontSize: "1.5rem",
                            color:
                              promptTitleIndex === prompts.length - 1
                                ? "#ddd"
                                : "#000",
                            cursor: "pointer",
                          }}
                          role="link"
                          onClick={() => handlePromptNavigation("next")}
                        />
                      </div>
                    </div>
                  </div>
                )}
                {/* VARIABLE TAB  */}

                {currentTab === "variable" && (
                  <div className="w-100 my-3">
                    {/* VARIABLE HEADER AND ADD NEW VARIABLE BUTTON */}

                    <div className="d-flex justify-content-between align-items-center ">
                      <h2>Variables</h2>

                      <FontAwesomeIcon
                        icon={faCirclePlus}
                        style={{ fontSize: "20px", cursor: "pointer" }}
                        role="button"
                        onClick={() => handleCreateNewVariable()}
                        title="Add New Variable"
                      />
                    </div>

                    {/* ALREADY SAVED VARIABLES IN DB */}

                    {variableFormDatas.length > 0 && (
                      <div className="mt-2 rounded bg-light p-3">
                        <div className="d-flex justify-content-start">
                          <h5 className="mr-2" style={{ width: "30%" }}>
                            Variable Names
                          </h5>
                          <h5 style={{ width: "30%" }}>Variable Values</h5>
                        </div>
                        {variableFormDatas.map((var_data, index) =>
                          Object.entries(var_data).map(([key, value]) => (
                            <VariableDisplayAndEdit
                              variable_name={key}
                              variable_value={value}
                              key={`${index}-${key}`}
                            />
                          ))
                        )}
                      </div>
                    )}

                    {/* CREATE NEW VARIABLE MODAL */}

                    {createNewVariable && (
                      <div className="mt-2">
                        <form
                          className="p-3 rounded bg-light"
                          style={{ position: "relative", height: "auto" }}
                          onSubmit={handleVariableFormSubmit}
                        >
                          <div className="w-100">
                            {/* VARIABLE NAME */}
                            <div>
                              <label for="variable-name" className="form-label">
                                Variable Name
                              </label>
                              <input
                                type="text"
                                placeholder="Enter Variable Name"
                                id="variable-name"
                                name="variable_name"
                                value={variableFormData.variable_name}
                                className="p-2 form-control mb-2"
                                style={{ display: "block", width: "300px" }}
                                onChange={handleVariableFormDataChange}
                              />
                              <p
                                className="text-danger"
                                style={{ fontSize: "14px", fontWeight: "bold" }}
                              >
                                All variable name should begin and end with '$'.
                                Ex: $Your_Variable_Name$
                              </p>
                            </div>
                            {/* VARIABLE VALUE */}
                            <div>
                              <label for="variable-name" className="form-label">
                                Variable Value
                              </label>
                              <textarea
                                type="text"
                                placeholder="Enter Variable Value"
                                id="variable-name"
                                name="variable_value"
                                value={variableFormData.variable_value}
                                className="p-2 form-control w-100"
                                style={{ display: "block", resize: "none" }}
                                onChange={handleVariableFormDataChange}
                              />
                            </div>
                          </div>

                          <div className="d-flex justify-content-end mt-2">
                            <button
                              type="reset"
                              className="bg-danger p-1 mr-2"
                              style={{
                                width: "100px",
                                fontSize: "14px",
                                outline: "none",
                                border: "none",
                                color: "#fff",
                                borderRadius: "5px",
                              }}
                              onClick={() => setCreateNewVariable(false)}
                            >
                              Cancel
                            </button>

                            <input
                              type="submit"
                              className="bg-primary p-1"
                              style={{
                                width: "100px",
                                fontSize: "14px",
                                outline: "none",
                                border: "none",
                                color: "#fff",
                                borderRadius: "5px",
                              }}
                            />
                          </div>
                        </form>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
}

export default Prompt;
